import { css } from '@emotion/css';
import React, {useCallback, useEffect, useRef, useState } from 'react';
import ReactFlow, { ReactFlowInstance, ReactFlowProvider} from 'reactflow';
import 'reactflow/dist/style.css';
import { Field, ProcessedData, nameToMne } from './utils';
import PayloadPanel from '../module/PayloadPanel';
import EIDPU from '../module/EIDPU';
import PayloadTB from './PayloadTB';
import ValueBpx from './ValueBox';
import EMAG from 'module/EMAG';
import mNLP from './mNLP';
import EESA from './EESA';
import Pip from './Pip';
import FPP from './FPP';
import Name from './Name';
import Heater from './Heater';
import ICB from 'module/ICB';
import CustomEdge from './CustomEdge';
import Boom from 'module/Boom';
import Spoc from './SPOC';
import PayloadHtb from './PayloadHtb';
import Cam from './Cam';
import BoomPlug from './BoomPlug';
import PlimBox from './Plim';
import VModeBox from './VModeBox';
import { useTimeRangeContext } from './TimeRangeContext';

type TelemetryDictionary = {
  [key: string]: TelemetryData;
};

type AppProps = {
  dbData: Field[];
  source: string;
  // ... other props if there are any
  width: number;
  height: number;
  options: {
    text: string;                  // Corresponds to the 'addTextInput' option
    showSeriesCount: boolean;      // Corresponds to the 'addBooleanSwitch' opton
    seriesCountSize: 'sm' | 'md' | 'lg';  // Corresponds to the 'addRadio' option
    X: number;           // Corresponds to the 'addNumberInput' option
    Y: number;
    Zoom: number;
  };
  influxData: ProcessedData;
};

type TelemetryData = {
  limit: any; // Replace 'any' with the actual type of limit
  telemetry: string; // Replace 'any' with the actual type of cnvVlue
  unit: string; // Replace 'any' with the actual type of unit
  live: string;
  mne: string;
  spacecraft: string;
};

const reactAppWrapper = css`

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.react-flow__node {
  z-index: 10 !important;
}

.react-flow__edges {
  z-index: 9 !important;
}

.react-flow__node-eidpu{
  z-index: 6 !important;
}

.react-flow__node-payloadPanel{
  z-index: 5 !important;
}

.react-flow__edge-span{
  z-index: 3 !important;
}

  position: relative;
  background-color: #161616;
  color: #FFFFFF;
  font-size: 32px;
  font-family: monospace;
  box-sizing: border-box;
  text-align: center;
`;

const nodeTypes = {spoc:Spoc, boomPlug:BoomPlug,vmodeBox:VModeBox,plim:PlimBox,eidpu:EIDPU,boom:Boom,payloadHtb: PayloadHtb,cam:Cam, icb:ICB, name:Name, heater:Heater, mNLP:mNLP, pip:Pip, FPP:FPP,eesa:EESA ,payloadPanel: PayloadPanel, emag:EMAG, payloadTB: PayloadTB, valuebox: ValueBpx}
const edgeTypes = {customEdge: CustomEdge}

const App: React.FC<AppProps> = ({dbData,source, width, height, options, influxData}) => {

  //console.log(influxData, 'influxData');

  const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance | null>(null);
  const { guiValues } = useTimeRangeContext();
  // Use refs to always have the current values
  const pbTimeRef = useRef("0");
  const pbLoopRef = useRef(0);

  const onInit = useCallback((instance: ReactFlowInstance) => {
    setReactFlowInstance(instance);
  }, []);

  useEffect(() => {
    if (reactFlowInstance ) {
      // @ts-ignore
      reactFlowInstance.fitView();
    }
  }
  ,[width, height]);

  function isWithinDifference(unixTimestamp: number) {

    // Convert the Unix timestamp to a JavaScript Date object
    const timestampDate = new Date(unixTimestamp * 1000);

    // Get the current time
    const currentDate = new Date();

    // Calculate the difference in seconds
    const timeDifference = (currentDate.getTime() - timestampDate.getTime()) / 1000;

    // Compare and return
    if (timeDifference <= 30) {
      return "rgb(72, 200, 44)";
    } else if (timeDifference <= 43200) { // 43200 seconds = 12 hours 
        return "#CA51EC";
    } else {
        return "rgb(68, 169, 241)"; //blue -- stale data
    }
  }

  const getDataFromSource = useCallback((mne: string) => {
    const mneColumn = dbData ? dbData.find(column => column.name === "mne") : null;
    if (!mneColumn) {
      return { limit: null, cnvValue: null, unit: null, live: "", mne: mne }; // mne column not found
    }
  
    const mneIndex = mneColumn.values.indexOf(mne);
    if (mneIndex === -1) {
      return { limit: null, cnvValue: null, unit: null, live:"", mne: mne }; // mne not found
    }
  
    // Safely get the other columns
    const limitColumn = dbData.find(column => column.name === "limit");
    const cnvValueColumn = dbData.find(column => column.name === "cnvValue");
    const unitColumn = dbData.find(column => column.name === "units");
    const timeColumn = dbData.find(column => column.name === "tInsert");
  
    const limit = limitColumn ? limitColumn.values[mneIndex] : null;
    let cnvValue = cnvValueColumn ? cnvValueColumn.values[mneIndex] : null;
    let unit = unitColumn ? unitColumn.values[mneIndex] : null;
    let live = timeColumn ? isWithinDifference(timeColumn.values[mneIndex]) : "";

      // Append the degree symbol to the unit if the unit is 'C'
    if (unit === 'C' || unit === 'degC' || unit === 'deg C') {
      unit = '\u00B0C';
    }else if(unit === 'packets'){
      unit = 'Pkt'
    }else if(unit === 'sec'){
      unit = 'S'
    }
    else if(unit === 'counts' || unit === 'Counters' || unit === 'cnts'){
      unit = 'Cnt'
    }
    else if(unit === 'None'|| unit === 'bool' || unit === 'num'){
      unit = null
    }

    // Special handling for 
    if (mne === 'cdh_plim_uptime' || mne === 'CDH_SPOC_UPTIME_agg'.toLowerCase()) {
      cnvValue = (parseFloat(cnvValue) / 1000).toFixed(2);
      unit = 's';
    } else if (cnvValue !== null && !isNaN(parseFloat(cnvValue))) {
      // Check if cnvValue is a number and convert/round it if s
      const numValue = parseFloat(cnvValue);
      const [integerPart, decimalPart] = numValue.toString().split('.');

      // Convert to exponential form if the integer part is too long
        if (integerPart.length > 7) {
      cnvValue = numValue.toExponential(2);
        } else if (decimalPart) {
          // It's a float, parse and keep 2 decimal places
          cnvValue = numValue.toFixed(2);
        }
    }
    
    return { limit, cnvValue, unit, live, mne };
  },[dbData]); 

  const createTelemetryDictionary = useCallback(() => {
    const telemetryDict: Record<string, TelemetryData> = {};
  
    Object.entries(nameToMne).forEach(([name, mne]) => {
      let newMne = mne + "_agg"; // First try with _agg added
      let data = getDataFromSource(newMne.toLowerCase());
  
      // If the cnvValue is null or empty, try with the regular mne
      if (!data.cnvValue) {
        newMne = mne; // Use the regular mnemonic
        data = getDataFromSource(newMne.toLowerCase());
      }
  
      telemetryDict[name] = {
        limit: data.limit,
        mne: data.mne,
        telemetry: data.cnvValue,
        unit: data.unit,
        live: data.live,
        spacecraft: source ? source : ""
      };
    });
  
    return telemetryDict;
  }, [getDataFromSource, nameToMne, source]);

  const telemetryDictionary = createTelemetryDictionary();

  const [nodes, setNodes] = useState([
    { id: 'Panel', type: 'payloadPanel', position:{x:0, y:50}, data:{'source':source}},

    { id: 'EIDPU Module', type: 'eidpu',parentNode:'Panel', position:{x:900, y:675}, data:{power:telemetryDictionary['PLIM Power EIDPU']}},
    
    { id: 'PlIM', type: 'plim',parentNode:'Panel', position: { x: 1200, y:1730 }, data: {name:'Payload Interface Module', power:telemetryDictionary['SPOC Power Plim'],priEn:telemetryDictionary['Plim Pri En'], priOn:telemetryDictionary['Plim Pri'],secEn:telemetryDictionary['Plim Sec En'],secOn:telemetryDictionary['Plim Sec'],value: {'Uptimes': {'dbData':telemetryDictionary['Uptimes'],'fname':''},'Curr': {'dbData':telemetryDictionary['Plim Pwri'],'fname':''},'28Vi':{'dbData':telemetryDictionary['Plim ISOi'],'fname':''}}}},
    { id: 'SpocB', type: 'spoc',parentNode:'Panel', position:{x:300, y:1730}, data:{name:'Spoc',pripwr:telemetryDictionary['Spoc A Volt'],secpwr:telemetryDictionary['Spoc B Volt'], value:{'Spoc Uptime':{'dbData':telemetryDictionary['Spoc Uptime'], 'fname':''},'SpocA Volt':{'dbData':telemetryDictionary['Spoc A Volt'], 'fname':''},'SpocB Volt':{'dbData':telemetryDictionary['Spoc B Volt'], 'fname':''},'SpocA Curr':{'dbData':telemetryDictionary['Spoc A Curr'], 'fname':''},'SpocB Curr':{'dbData':telemetryDictionary['Spoc B Curr'], 'fname':''}}}},  

    { id: 'ICB', type: 'icb', parentNode:'EIDPU Module',position: { x: 675, y: 50 }, data: {name:'Instrument Control Board (ICB)',textbox_container_class:'icp-container',value: {'Board ID':{'dbData':telemetryDictionary['Board ID'],'fname':'Board ID'},'FSW Major':{'dbData':telemetryDictionary['FSW major'],'fname':'Flight software major version'},'FSW minor':{'dbData':telemetryDictionary['FSW minor'],'fname':'Flight software minor version'},'FSW Patch':{'dbData':telemetryDictionary['FSW Patch'],'fname':'Flight software patch version'}}}},
    { id: 'Power and Current Monitors', type: 'payloadTB', parentNode:'ICB',position: { x: 500, y: 375 }, data: {name:'Power & Current',textbox_container_class:'pncMonitor',value: {'Imon Ref':{'dbData':telemetryDictionary['Imon Ref'],'fname':'Reference current monitor'},'+28v':{'dbData':telemetryDictionary['Vmon 28'],'fname':'ICB +28v Voltage'},'+15v':{'dbData':telemetryDictionary['Vmon 15'],'fname':'ICB +15v Voltage'},'+3.3v':{'dbData':telemetryDictionary['Vmon 3_3'],'fname':'ICB +3.3v Voltage'},'-15v':{'dbData':telemetryDictionary['Vmonm 15'],'fname':'ICB -15v Voltage'},'Corev':{'dbData':telemetryDictionary['Vmon Core'],'fname':'ICB Core Voltage'},'ICB Temp':{'dbData':telemetryDictionary['ICB Temp'],'fname':'ICB Temp'},}}},
    { id: 'Command Interface', type: 'payloadTB', parentNode:'ICB',position: { x: 25, y:325 }, data: {name:'Command Interface',textbox_container_class:'cmdInter',value: {'Board ID':{'dbData':telemetryDictionary['Board ID'], 'fname':''},'FSW major': {'dbData':telemetryDictionary['FSW major'],'fname':'FSW major version'},'FSW minor': {'dbData':telemetryDictionary['FSW minor'],'fname':'FSW minor version'},'FSW patch': {'dbData':telemetryDictionary['FSW Patch'],'fname':'FSW patch version'},'Gnd Cmd Cnt':{'dbData':telemetryDictionary['Gnd Cmd cnt'],'fname':'Ground command received'},'SC Cmd Cnt':{'dbData':telemetryDictionary['Sc Cmd cnt'],'fname':'Spacecraft-generated commands received'},'CRC Error':{'dbData':telemetryDictionary['CRC error'],'fname':'CRC errors detected'},'Cmd Received':{'dbData':telemetryDictionary['Cmd Received cnt'],'fname':'Command Recevied'}}}},
    { id: 'ICB EESA', type: 'payloadTB', parentNode:'ICB',position: { x: 500, y: 50 }, data: {name:'ICB EESA',textbox_container_class:'icbEesa',value: {'+15i':{'dbData':telemetryDictionary['EESA p15i'],'fname':'EESA +15V current'},'-15i':{'dbData':telemetryDictionary['EESA n15i'],'fname':'EESA -15V current'},'+3.3i':{'dbData':telemetryDictionary['EESA p3_3i'],'fname':'EESA +3.3V current'},'+28i':{'dbData':telemetryDictionary['EESA 28i'],'fname':'EESA +28V current'}}}},
    { id: 'PPS', type: 'payloadTB', parentNode:'ICB',position: { x: 50, y: 50 }, data: {name:'Pulse Per Second(PPS)',textbox_container_class:'ppsBox',value: {'Missed PPS':{'dbData':telemetryDictionary['Missed PPS'],'fname':'PPS signal missed'},'Irr PPS':{'dbData':telemetryDictionary['Irregular PPS'],'fname':'Irregular SC PPS'},'PPS delta':{'dbData':telemetryDictionary['PPS delta'],'fname':'Delta between the last PPS and the previous one.'}}}},
    { id: 'EMAG board', type: 'payloadTB', parentNode:'EIDPU Module',position: { x: 125, y: 625 }, data: {name:'Magnetometer(EMAG) Board',  'textbox_container_class':'EmagBoard',current:telemetryDictionary['EMag p15i'],value: {'+15i':{'dbData':telemetryDictionary['EMag p15i'],'fname':'EMAG +15v current'},'-15i':{'dbData':telemetryDictionary['EMag n15i'],'fname':'EMAG -15v current'},'+3.3i':{'dbData':telemetryDictionary['EMag p3_3i'],'fname':'EMAG +3.3v current'}}}},
    { id: 'ELP board', type: 'payloadTB', parentNode:'EIDPU Module',position: { x: 25, y: 50 }, data: {name:'Langmuir Probe(ELP) Board',power:telemetryDictionary['ELP Power'], 'textbox_container_class':'ElpBoard',value: {'+15i':{'dbData':telemetryDictionary['ELP p15i'],'fname':'ELP +15v current'},'-15i':{'dbData':telemetryDictionary['ELP n15i'],'fname':'ELP -15v current'},'+3.3i':{'dbData':telemetryDictionary['ELP p3_3i'],'fname':'ELP +3.3v current'},'+15v':{'dbData':telemetryDictionary['ELP p15v'],'fname':'ELP +15v voltage'},'-15v':{'dbData':telemetryDictionary['ELP n15v'],'fname':'ELP -15v voltage'},'+5v':{'dbData':telemetryDictionary['ELP p5v'],'fname':'ELP +5v voltage'},'+3.3v':{'dbData':telemetryDictionary['ELP p3_3v'],'fname':'ELP +3.3v voltage'},'PIPt':{'dbData':telemetryDictionary['TL Pip Temp'],'fname':'Pip Temp'},'ADCt':{'dbData':telemetryDictionary['Tr Adc Temp'],'fname':'FPP Temperature'},'mNLPt':{'dbData':telemetryDictionary['LL mNLP Temp'],'fname':'mNLP Temp'}}}},

    { id: 'Cam', type: 'cam', parentNode:'Panel',position: { x: 2200, y: 25 }, data: {value: {'P':{'dbData':telemetryDictionary['Cam On'],'fname':''},'I':{'dbData':telemetryDictionary['Cam Curr'],'fname':''},'V':{'dbData':telemetryDictionary['Cam Volt'],'fname':''}, 'T*':{'dbData':telemetryDictionary['Cam Temp'],'fname':''}}}},

    { id: 'Boom', type: 'boom', parentNode:'Panel',position: { x: 25, y: 925 }, data: {deploy:false}},
    { id: 'Boom VB', type: 'valuebox', parentNode:'Boom',position: { x: 325, y: 570 }, data: {name:'Boom Temp Sensor',value:{'TR':{'dbData':telemetryDictionary['Boom Temp'],'fname':''}}}},
    { id: 'BoomPlug', type: 'boomPlug', parentNode:'Boom',position: { x: 80, y: 520 }, data: {name:'Boom Temp Sensor',priArm:telemetryDictionary['Boom Arm'], priFire:telemetryDictionary['Boom Fire'],secArm:telemetryDictionary['Boom Sec Arm'],secFire:telemetryDictionary['Boom Sec Fire']}},
    { id: 'mNLP-1', type: 'mNLP', parentNode:'Boom',position: { x: 150, y: 460 }, data: {name:'multi-Needle Langmuir Probe(mNLP)',value:{}}},
    { id: 'EIDPU Htr', type: 'heater',parentNode:'Panel', position: { x: 1794.5, y:500 }, data: {name:'EIDPU Heater', mode:telemetryDictionary['EIDPU Control Mode'],state:telemetryDictionary['EIDPU State'],value: {}}},

    { id: 'Pip-2', type: 'pip', parentNode:'Panel',position: { x:1700, y: 25 }, data: {name: 'Planar Ion Probe 2(PIP 2)'}},
    { id: 'FPP', type: 'FPP', parentNode:'Panel',position: { x: 1350, y: 25 }, data: {value:{}}},
    { id: 'EMAG VB', type: 'payloadHtb', parentNode:'Boom',position: { x: 100, y: 25 }, data: {name:'Fluxgate Magnetometer (EMAG)',textbox_container_class:'fluxgateEmag',value:{'+15v':{'dbData':telemetryDictionary['Emag Vmon p15'],'fname':'EMAG +15v voltage'},'-15v':{'dbData':telemetryDictionary['Emag Vmon n15'],'fname':'EMAG -15v voltage'},'+3.3v':{'dbData':telemetryDictionary['Emag Vmonm p3_3'],'fname':'EMAG +3.3v voltage'},'+2.5v':{'dbData':telemetryDictionary['Emag Vmon p2_5'],'fname':'EMAG +2.5v voltage'},'+5v':{'dbData':telemetryDictionary['Vmon p5'],'fname':'EMAG +5v Voltage'},'-8.3v':{'dbData':telemetryDictionary['Emag Vmon n8_3'],'fname':'EMAG -8.3v voltage'},'PCB Temp':{'dbData':telemetryDictionary['Pcb Temp'],'fname':'EMAG PCB temperature'},'Sensor Temp':{'dbData':telemetryDictionary['Sensor Temp'],'fname':'EMAG Sensor Temperature'},'Cmd Acc':{'dbData':telemetryDictionary['Cmd Accept cnt'],'fname':'Command Accept'},'Cmd Rjt':{'dbData':telemetryDictionary['Cmd Reject cnt'],'fname':'Commands Reject'},'Range Mode':{'dbData':telemetryDictionary['Range Mode'],'fname':'Range Mode'},'PPS Mode':{'dbData':telemetryDictionary['PPS Mode'],'fname':'PPS Mode'}}}},

    { id: 'EMAG Htr', type: 'heater',parentNode:'Panel', position: { x: 25, y:820 }, data: {name:'EMAG Heater', classname:'EMAGHtr',state:telemetryDictionary['EMAG State']}},

    { id: 'EESA', type: 'eesa', parentNode:'Panel',position: { x: 425, y: 25 }, data: {curr:telemetryDictionary['EESA p3_3i'],valueI: {'HVv':{'dbData':telemetryDictionary['Vmon iraw'],'fname':'Ion Raw HV Voltage'},'HVi':{'dbData':telemetryDictionary['Irawi'],'fname':'Ion Raw HV Current'},'MCPv':{'dbData':telemetryDictionary['EESAi McpV'], 'fname':''},'ACCv':{'dbData':telemetryDictionary['EESAi Accelv'], 'fname':'ion Acceleration voltage'}},value: {'HV':{'dbData':telemetryDictionary['EESA HV Status'],'fname':'HV Status'},'DT':{'dbData':telemetryDictionary['Digital Temp'],'fname':'Digital Temp'},'ET':{'dbData':telemetryDictionary['Eandoet Temp'],'fname':'eANDOET Temp'}},valueE: {'HVv':{'dbData':telemetryDictionary['Vmon eraw'],'fname':'electron Raw HV voltage'},'HVi':{'dbData':telemetryDictionary['Erawi'],'fname':'electron Raw HV current'},'MCPv':{'dbData':telemetryDictionary['EESAe McpV'], 'fname':''}},valueEAtt:{'Hemi':{'dbData':telemetryDictionary['EESAe Hemiv'],'fname':''},'Def1':{'dbData':telemetryDictionary['EESAe def1v'],'fname':''},'Def2':{'dbData':telemetryDictionary['EESAe def2v'],'fname':''},'Spol':{'dbData':telemetryDictionary['EESAe SpoielrV'],'fname':''},'T':{'dbData':telemetryDictionary['Eanal Temp'],'fname':'electron Analyzer Temp'}}, valueIAtt:{'Hemi':{'dbData':telemetryDictionary['EESAi Hemiv'],'fname':''},'Def1':{'dbData':telemetryDictionary['EESAi def1v'],'fname':''},'Def2':{'dbData':telemetryDictionary['EESAi def2v'],'fname':''},'Spol':{'dbData':telemetryDictionary['EESAi SpoielrV'],'fname':''},'T':{'dbData':telemetryDictionary['Iana Temp'],'fname':'ion Analyzer temp'},'MCC':{'dbData':telemetryDictionary['EESAi attn mode change'],'fname':'Attenuator Mode Change Count'},'MMC':{'dbData':telemetryDictionary['EESAi attn MisMatch Cnt'],'fname':'MisMatch Count'},'Stat':{'dbData':telemetryDictionary['EESAi attn state'],'fname':''}}}},
    { id: 'EESA Htr', type: 'heater',parentNode:'Panel', position: { x: 50, y:480 }, data: {name:'EESA Heater', mode:telemetryDictionary['EESA Control Mode'],state:telemetryDictionary['EESA State'], value: {}}},
    { id: 'Pip-1', type: 'pip', parentNode:'Panel',position: { x: 75, y: 25 }, data: {name:'Planar Ion Probe 1(PIP 1)',value:{}}},
    { id: 'InfoBox', type: 'vmodeBox',parentNode:'', position:{x:0, y:-65}, data:{name:'', VMode:telemetryDictionary['Vehicle Mode Agg'], playBackValue: {time: pbTimeRef.current, loop: pbLoopRef.current}}},

]);

  const createNodes = useCallback((telemetryDictionary: TelemetryDictionary) => {
    return [
      { id: 'Panel', type: 'payloadPanel', position:{x:0, y:50}, data:{'source':source}},

      { id: 'EIDPU Module', type: 'eidpu',parentNode:'Panel', position:{x:900, y:675}, data:{power:telemetryDictionary['PLIM Power EIDPU']}},
      
      { id: 'PlIM', type: 'plim',parentNode:'Panel', position: { x: 1200, y:1730 }, data: {name:'Payload Interface Module', power:telemetryDictionary['SPOC Power Plim'],priEn:telemetryDictionary['Plim Pri En'], priOn:telemetryDictionary['Plim Pri'],secEn:telemetryDictionary['Plim Sec En'],secOn:telemetryDictionary['Plim Sec'],value: {'Uptimes': {'dbData':telemetryDictionary['Uptimes'],'fname':''},'Curr': {'dbData':telemetryDictionary['Plim Pwri'],'fname':''},'28Vi':{'dbData':telemetryDictionary['Plim ISOi'],'fname':''}}}},
      { id: 'SpocB', type: 'spoc',parentNode:'Panel', position:{x:300, y:1730}, data:{name:'Spoc',pripwr:telemetryDictionary['Spoc A Volt'],secpwr:telemetryDictionary['Spoc B Volt'], value:{'Spoc Uptime':{'dbData':telemetryDictionary['Spoc Uptime'], 'fname':''},'SpocA Volt':{'dbData':telemetryDictionary['Spoc A Volt'], 'fname':''},'SpocB Volt':{'dbData':telemetryDictionary['Spoc B Volt'], 'fname':''},'SpocA Curr':{'dbData':telemetryDictionary['Spoc A Curr'], 'fname':''},'SpocB Curr':{'dbData':telemetryDictionary['Spoc B Curr'], 'fname':''}}}},  

      { id: 'ICB', type: 'icb', parentNode:'EIDPU Module',position: { x: 675, y: 50 }, data: {name:'Instrument Control Board (ICB)',textbox_container_class:'icp-container',value: {'Board ID':{'dbData':telemetryDictionary['Board ID'],'fname':'Board ID'},'FSW Major':{'dbData':telemetryDictionary['FSW major'],'fname':'Flight software major version'},'FSW minor':{'dbData':telemetryDictionary['FSW minor'],'fname':'Flight software minor version'},'FSW Patch':{'dbData':telemetryDictionary['FSW Patch'],'fname':'Flight software patch version'}}}},
      { id: 'Power and Current Monitors', type: 'payloadTB', parentNode:'ICB',position: { x: 500, y: 375 }, data: {name:'Power & Current',textbox_container_class:'pncMonitor',value: {'Imon Ref':{'dbData':telemetryDictionary['Imon Ref'],'fname':'Reference current monitor'},'+28v':{'dbData':telemetryDictionary['Vmon 28'],'fname':'ICB +28v Voltage'},'+15v':{'dbData':telemetryDictionary['Vmon 15'],'fname':'ICB +15v Voltage'},'+3.3v':{'dbData':telemetryDictionary['Vmon 3_3'],'fname':'ICB +3.3v Voltage'},'-15v':{'dbData':telemetryDictionary['Vmonm 15'],'fname':'ICB -15v Voltage'},'Corev':{'dbData':telemetryDictionary['Vmon Core'],'fname':'ICB Core Voltage'},'ICB Temp':{'dbData':telemetryDictionary['ICB Temp'],'fname':'ICB Temp'},}}},
      { id: 'Command Interface', type: 'payloadTB', parentNode:'ICB',position: { x: 25, y:325 }, data: {name:'Command Interface',textbox_container_class:'cmdInter',value: {'Board ID':{'dbData':telemetryDictionary['Board ID'], 'fname':''},'FSW major': {'dbData':telemetryDictionary['FSW major'],'fname':'FSW major version'},'FSW minor': {'dbData':telemetryDictionary['FSW minor'],'fname':'FSW minor version'},'FSW patch': {'dbData':telemetryDictionary['FSW Patch'],'fname':'FSW patch version'},'Gnd Cmd Cnt':{'dbData':telemetryDictionary['Gnd Cmd cnt'],'fname':'Ground command received'},'SC Cmd Cnt':{'dbData':telemetryDictionary['Sc Cmd cnt'],'fname':'Spacecraft-generated commands received'},'CRC Error':{'dbData':telemetryDictionary['CRC error'],'fname':'CRC errors detected'},'Cmd Received':{'dbData':telemetryDictionary['Cmd Received cnt'],'fname':'Command Recevied'}}}},
      { id: 'ICB EESA', type: 'payloadTB', parentNode:'ICB',position: { x: 500, y: 50 }, data: {name:'ICB EESA',textbox_container_class:'icbEesa',value: {'+15i':{'dbData':telemetryDictionary['EESA p15i'],'fname':'EESA +15V current'},'-15i':{'dbData':telemetryDictionary['EESA n15i'],'fname':'EESA -15V current'},'+3.3i':{'dbData':telemetryDictionary['EESA p3_3i'],'fname':'EESA +3.3V current'},'+28i':{'dbData':telemetryDictionary['EESA 28i'],'fname':'EESA +28V current'}}}},
      { id: 'PPS', type: 'payloadTB', parentNode:'ICB',position: { x: 50, y: 50 }, data: {name:'Pulse Per Second(PPS)',textbox_container_class:'ppsBox',value: {'Missed PPS':{'dbData':telemetryDictionary['Missed PPS'],'fname':'PPS signal missed'},'Irr PPS':{'dbData':telemetryDictionary['Irregular PPS'],'fname':'Irregular SC PPS'},'PPS delta':{'dbData':telemetryDictionary['PPS delta'],'fname':'Delta between the last PPS and the previous one.'}}}},
      { id: 'EMAG board', type: 'payloadTB', parentNode:'EIDPU Module',position: { x: 125, y: 625 }, data: {name:'Magnetometer(EMAG) Board',  'textbox_container_class':'EmagBoard',current:telemetryDictionary['EMag p15i'],value: {'+15i':{'dbData':telemetryDictionary['EMag p15i'],'fname':'EMAG +15v current'},'-15i':{'dbData':telemetryDictionary['EMag n15i'],'fname':'EMAG -15v current'},'+3.3i':{'dbData':telemetryDictionary['EMag p3_3i'],'fname':'EMAG +3.3v current'}}}},
      { id: 'ELP board', type: 'payloadTB', parentNode:'EIDPU Module',position: { x: 25, y: 50 }, data: {name:'Langmuir Probe(ELP) Board',power:telemetryDictionary['ELP Power'], 'textbox_container_class':'ElpBoard',value: {'+15i':{'dbData':telemetryDictionary['ELP p15i'],'fname':'ELP +15v current'},'-15i':{'dbData':telemetryDictionary['ELP n15i'],'fname':'ELP -15v current'},'+3.3i':{'dbData':telemetryDictionary['ELP p3_3i'],'fname':'ELP +3.3v current'},'+15v':{'dbData':telemetryDictionary['ELP p15v'],'fname':'ELP +15v voltage'},'-15v':{'dbData':telemetryDictionary['ELP n15v'],'fname':'ELP -15v voltage'},'+5v':{'dbData':telemetryDictionary['ELP p5v'],'fname':'ELP +5v voltage'},'+3.3v':{'dbData':telemetryDictionary['ELP p3_3v'],'fname':'ELP +3.3v voltage'},'PIPt':{'dbData':telemetryDictionary['TL Pip Temp'],'fname':'Pip Temp'},'ADCt':{'dbData':telemetryDictionary['Tr Adc Temp'],'fname':'FPP Temperature'},'mNLPt':{'dbData':telemetryDictionary['LL mNLP Temp'],'fname':'mNLP Temp'}}}},
  
      { id: 'Cam', type: 'cam', parentNode:'Panel',position: { x: 2200, y: 25 }, data: {value: {'P':{'dbData':telemetryDictionary['Cam On'],'fname':''},'I':{'dbData':telemetryDictionary['Cam Curr'],'fname':''},'V':{'dbData':telemetryDictionary['Cam Volt'],'fname':''}, 'T*':{'dbData':telemetryDictionary['Cam Temp'],'fname':''}}}},
  
      { id: 'Boom', type: 'boom', parentNode:'Panel',position: { x: 25, y: 925 }, data: {deploy:false}},
      { id: 'Boom VB', type: 'valuebox', parentNode:'Boom',position: { x: 325, y: 570 }, data: {name:'Boom Temp Sensor',value:{'TR':{'dbData':telemetryDictionary['Boom Temp'],'fname':''}}}},
      { id: 'BoomPlug', type: 'boomPlug', parentNode:'Boom',position: { x: 80, y: 520 }, data: {name:'Boom Temp Sensor',priArm:telemetryDictionary['Boom Arm'], priFire:telemetryDictionary['Boom Fire'],secArm:telemetryDictionary['Boom Sec Arm'],secFire:telemetryDictionary['Boom Sec Fire']}},
      { id: 'mNLP-1', type: 'mNLP', parentNode:'Boom',position: { x: 150, y: 460 }, data: {name:'multi-Needle Langmuir Probe(mNLP)',value:{}}},
      { id: 'EIDPU Htr', type: 'heater',parentNode:'Panel', position: { x: 1794.5, y:500 }, data: {name:'EIDPU Heater', mode:telemetryDictionary['EIDPU Control Mode'],state:telemetryDictionary['EIDPU State'],value: {}}},
  
      { id: 'Pip-2', type: 'pip', parentNode:'Panel',position: { x:1700, y: 25 }, data: {name: 'Planar Ion Probe 2(PIP 2)'}},
      { id: 'FPP', type: 'FPP', parentNode:'Panel',position: { x: 1350, y: 25 }, data: {value:{}}},
      { id: 'EMAG VB', type: 'payloadHtb', parentNode:'Boom',position: { x: 100, y: 25 }, data: {name:'Fluxgate Magnetometer (EMAG)',textbox_container_class:'fluxgateEmag',value:{'+15v':{'dbData':telemetryDictionary['Emag Vmon p15'],'fname':'EMAG +15v voltage'},'-15v':{'dbData':telemetryDictionary['Emag Vmon n15'],'fname':'EMAG -15v voltage'},'+3.3v':{'dbData':telemetryDictionary['Emag Vmonm p3_3'],'fname':'EMAG +3.3v voltage'},'+2.5v':{'dbData':telemetryDictionary['Emag Vmon p2_5'],'fname':'EMAG +2.5v voltage'},'+5v':{'dbData':telemetryDictionary['Vmon p5'],'fname':'EMAG +5v Voltage'},'-8.3v':{'dbData':telemetryDictionary['Emag Vmon n8_3'],'fname':'EMAG -8.3v voltage'},'PCB Temp':{'dbData':telemetryDictionary['Pcb Temp'],'fname':'EMAG PCB temperature'},'Sensor Temp':{'dbData':telemetryDictionary['Sensor Temp'],'fname':'EMAG Sensor Temperature'},'Cmd Acc':{'dbData':telemetryDictionary['Cmd Accept cnt'],'fname':'Command Accept'},'Cmd Rjt':{'dbData':telemetryDictionary['Cmd Reject cnt'],'fname':'Commands Reject'},'Range Mode':{'dbData':telemetryDictionary['Range Mode'],'fname':'Range Mode'},'PPS Mode':{'dbData':telemetryDictionary['PPS Mode'],'fname':'PPS Mode'}}}},
  
      { id: 'EMAG Htr', type: 'heater',parentNode:'Panel', position: { x: 25, y:820 }, data: {name:'EMAG Heater', classname:'EMAGHtr',state:telemetryDictionary['EMAG State']}},
  
      { id: 'EESA', type: 'eesa', parentNode:'Panel',position: { x: 425, y: 25 }, data: {curr:telemetryDictionary['EESA p3_3i'],valueI: {'HVv':{'dbData':telemetryDictionary['Vmon iraw'],'fname':'Ion Raw HV Voltage'},'HVi':{'dbData':telemetryDictionary['Irawi'],'fname':'Ion Raw HV Current'},'MCPv':{'dbData':telemetryDictionary['EESAi McpV'], 'fname':''},'ACCv':{'dbData':telemetryDictionary['EESAi Accelv'], 'fname':'ion Acceleration voltage'}},value: {'HV':{'dbData':telemetryDictionary['EESA HV Status'],'fname':'HV Status'},'DT':{'dbData':telemetryDictionary['Digital Temp'],'fname':'Digital Temp'},'ET':{'dbData':telemetryDictionary['Eandoet Temp'],'fname':'eANDOET Temp'}},valueE: {'HVv':{'dbData':telemetryDictionary['Vmon eraw'],'fname':'electron Raw HV voltage'},'HVi':{'dbData':telemetryDictionary['Erawi'],'fname':'electron Raw HV current'},'MCPv':{'dbData':telemetryDictionary['EESAe McpV'], 'fname':''}},valueEAtt:{'Hemi':{'dbData':telemetryDictionary['EESAe Hemiv'],'fname':''},'Def1':{'dbData':telemetryDictionary['EESAe def1v'],'fname':''},'Def2':{'dbData':telemetryDictionary['EESAe def2v'],'fname':''},'Spol':{'dbData':telemetryDictionary['EESAe SpoielrV'],'fname':''},'T':{'dbData':telemetryDictionary['Eanal Temp'],'fname':'electron Analyzer Temp'}}, valueIAtt:{'Hemi':{'dbData':telemetryDictionary['EESAi Hemiv'],'fname':''},'Def1':{'dbData':telemetryDictionary['EESAi def1v'],'fname':''},'Def2':{'dbData':telemetryDictionary['EESAi def2v'],'fname':''},'Spol':{'dbData':telemetryDictionary['EESAi SpoielrV'],'fname':''},'T':{'dbData':telemetryDictionary['Iana Temp'],'fname':'ion Analyzer temp'},'MCC':{'dbData':telemetryDictionary['EESAi attn mode change'],'fname':'Attenuator Mode Change Count'},'MMC':{'dbData':telemetryDictionary['EESAi attn MisMatch Cnt'],'fname':'MisMatch Count'},'Stat':{'dbData':telemetryDictionary['EESAi attn state'],'fname':''}}}},
      { id: 'EESA Htr', type: 'heater',parentNode:'Panel', position: { x: 50, y:480 }, data: {name:'EESA Heater', mode:telemetryDictionary['EESA Control Mode'],state:telemetryDictionary['EESA State'], value: {}}},
      { id: 'Pip-1', type: 'pip', parentNode:'Panel',position: { x: 75, y: 25 }, data: {name:'Planar Ion Probe 1(PIP 1)',value:{}}},
      { id: 'InfoBox', type: 'vmodeBox',parentNode:'', position:{x:0, y:-65}, data:{name:'', VMode:telemetryDictionary['Vehicle Mode Agg'], playBackValue: {time: pbTimeRef.current, loop: pbLoopRef.current}}},

    ]}, []);


    useEffect(() => {
      if (!guiValues.current.pbisInPlayBack) return;
    
      let isCancelled = false;
    
      const processData = async () => {
        // Initialize an empty object to store telemetry data
        let aggregatedData: TelemetryDictionary = {};
        const totalLoops = Object.keys(influxData).length;
    
        for (let i = 0; i < totalLoops; i++) {
          // Check if the process should stop
          if (!guiValues.current.pbisInPlayBack || isCancelled) {
            break;
          }

          const key = Object.keys(influxData)[i];
          const selectedData = influxData[key];
            pbTimeRef.current = key;
            const newValue = (i + 1)/totalLoops;
            pbLoopRef.current = newValue;

    
          // Loop through each item in selectedData and update the aggregatedData object
          selectedData.forEach(item => {
            // If the key already exists, update the telemetry value
            if (aggregatedData[item.name]) {
              if (item.cnvValue != '') {
                aggregatedData[item.name].telemetry = item.cnvValue;
              }
            } else {
              aggregatedData[item.name] = {
                telemetry: item.cnvValue,
                mne: item.mne,
                limit: "",
                unit: "",
                spacecraft: "",
                live: "rgb(68, 169, 241)",
              };
            }
          });
    
          // Create nodes from the aggregatedData object
          const newNodes = createNodes(aggregatedData);
          setNodes(newNodes);
    
          // Log the current loop progress
          //console.log(`${i + 1}/${totalLoops}`);
    
          // Wait for 2 seconds before processing the next item
          await new Promise(resolve => setTimeout(resolve, 2000));
        }
        guiValues.current.pbstopPlay()
        //console.log('Playback completed');
      };
    
      processData();
    
      // Cleanup function to cancel the loop if pbisInPlayBack changes
      return () => {
        isCancelled = true;
      };
    }, [influxData, guiValues.current.pbisInPlayBack]);

    useEffect(() => {
      //console.log('Playback started asfafa', guiValues.pbisInPlayBack);
      if (guiValues.current.pbisInPlayBack) return;
      const telemetryDictionary1 = createTelemetryDictionary();
    
      const newNodes = createNodes(telemetryDictionary1); // Function to create nodes from telemetryDictionary
      setNodes(newNodes);
    }, [dbData, createTelemetryDictionary]);
    
    
    console.log(guiValues.current.pbisInPlayBack);

  enum MarkerType {
    Arrow = 'arrow',
    ArrowClosed = 'arrowclosed',
  }

  const arrowWhite = {type: MarkerType.ArrowClosed, color: 'white', orient: 'auto-start-reverse', width: 15, height: 15};

  const initialEdges = [
  {id: 'ELP PIP1', source: 'ELP board',sourceHandle: 'source-left-2', target: 'Pip-1',targetHandle:'target-left', type:'customEdge',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'},markerStart:arrowWhite,zIndex:100},
  {id: 'ELP PIP1 Power', source: 'ELP board',sourceHandle: 'source-left-8', target: 'Pip-1',targetHandle:'target-left-1', type:'customEdge',style: { stroke: 'white', strokeWidth:5},markerEnd:arrowWhite,zIndex:100, data:{power:telemetryDictionary['ELP Power']}},
  {id: 'ELP PIP2', source: 'ELP board',sourceHandle: 'source-top-1', target: 'Pip-2',targetHandle:'target-bottom-1', type:'customEdge',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'},markerStart:arrowWhite,zIndex:100},
  {id: 'ELP PIP2 Power', source: 'ELP board',sourceHandle: 'source-top-2', target: 'Pip-2',targetHandle:'target-bottom', type:'customEdge',style: { stroke: 'white', strokeWidth:5},markerEnd:arrowWhite,zIndex:100, data:{power:telemetryDictionary['ELP Power']}},
  {id: 'ELP FPP', source: 'ELP board',sourceHandle: 'source-top-3', target: 'FPP',targetHandle:'target-bottom-1', type:'customEdge',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'},markerStart:arrowWhite,zIndex:100},
  {id: 'ELP FPP Power', source: 'ELP board',sourceHandle: 'source-top-4', target: 'FPP',targetHandle:'target-bottom', type:'customEdge',style: { stroke: 'white', strokeWidth:5},markerEnd:arrowWhite,zIndex:100, data:{power:telemetryDictionary['ELP Power']}},
  // {id: 'mNLPtoELP', source: 'ELP board',sourceHandle: 'source-left-1', target: 'mNLP-1',targetHandle:'target-left-1', type:'customEdge',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'},markerStart:arrowWhite,zIndex:100},
  // {id: 'ELPtomNLP', source: 'ELP board',sourceHandle: 'source-left-5', target: 'mNLP-1',targetHandle:'target-left-2', type:'customEdge',style: { stroke: 'white', strokeWidth:5},markerEnd:arrowWhite,zIndex:100, data:{power:telemetryDictionary['ELP Power']}},

  {id: 'ICB ELP Edge', source: 'ICB',sourceHandle: 'source-left-1', target: 'ELP board',targetHandle:'target-bottom-5', type:'customEdge',style: { stroke: 'white', strokeWidth:5},markerEnd: arrowWhite,zIndex:100, data:{power:telemetryDictionary['ELP Power']}},
  {id: 'ICB ELP Edge Data', source: 'ICB',sourceHandle: 'source-left-2', target: 'ELP board',targetHandle:'target-bottom', type:'customEdge',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'},markerStart: arrowWhite,zIndex:100},
  {id: 'ICB EMAG Edge', source: 'ICB',sourceHandle: 'source-left-3', target: 'EMAG board',targetHandle:'target-top-1', type:'customEdge',style: { stroke: 'white', strokeWidth:5},markerEnd: arrowWhite,zIndex:100, data:{current:telemetryDictionary['EMag p15i']}},
  {id: 'ICB EMAG Edge Data', source: 'ICB',sourceHandle: 'source-left-4', target: 'EMAG board',targetHandle:'target-top', type:'customEdge',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'},markerStart: arrowWhite,zIndex:100},
  {id: 'ICB EESA Edge', source: 'ICB',sourceHandle: 'source-top-3', target: 'EESA',targetHandle:'target-top-1', type:'customEdge',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'},markerStart:arrowWhite,zIndex:100},
  {id: 'ICB EESA Power', source: 'ICB',sourceHandle: 'source-top-4', target: 'EESA',targetHandle:'target-top', type:'customEdge',style: { stroke: 'white', strokeWidth:5},markerEnd: arrowWhite,zIndex:100, data:{current:telemetryDictionary['EESA p3_3i']}},
  {id: 'EMag Pwr', source: 'EMAG board',sourceHandle: 'source-left', target: 'EMAG VB',targetHandle:'target-right', type:'customEdge',style: { stroke: 'white', strokeWidth:5},markerEnd:arrowWhite,zIndex:100, data:{current:telemetryDictionary['EMag p15i']}},
  {id: 'EMAGtoEMagBoard', source: 'EMAG VB',sourceHandle: 'source-right', target: 'EMAG board',targetHandle:'target-left', type:'customEdge',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'},markerEnd:arrowWhite,zIndex:100},
  {id: 'edge14', source: 'EESA Htr',sourceHandle: 'source-right-1', target: 'EESA',targetHandle:'target-left-1', type:'customEdge',style: { stroke: 'white', strokeWidth:5},markerEnd:arrowWhite,zIndex:100, data:{power:telemetryDictionary['EESA State']}},
  {id: 'edge15', source: 'EIDPU Htr',sourceHandle: 'source-bottom', target: 'EIDPU Module',targetHandle:'target-top-1', type:'customEdge',style: { stroke: 'white', strokeWidth:5},markerEnd:arrowWhite,zIndex:100, data:{power:telemetryDictionary['EIDPU State']}},
  {id: 'edge15EMAG', source: 'EMAG Htr',sourceHandle: 'source-right-2', target: 'EMAG VB',targetHandle:'target-top', type:'customEdge',style: { stroke: 'white', strokeWidth:5},markerEnd:arrowWhite,zIndex:100, data:{power:telemetryDictionary['EMAG State']}},

  {id: 'edge16', source: 'PlIM',sourceHandle: 'source-left', target: 'Boom Enable Plug',targetHandle:'target-right', type:'customEdge',style: { stroke: 'white', strokeWidth:5},markerEnd:arrowWhite,zIndex:100},
  {id: 'edge17', source: 'PlIM',sourceHandle: 'source-right-1', target: 'EIDPU Module',targetHandle:'target-right', type:'customEdge',style: { stroke: 'white', strokeWidth:5},markerEnd:arrowWhite,zIndex:100, data:{power:telemetryDictionary['PLIM Power EIDPU']}},
  {id: 'edge18', source: 'Boom Enable Plug',sourceHandle: 'source-top', target: 'Boom',targetHandle:'target-bottom', type:'customEdge',style: { stroke: 'white', strokeWidth:5},markerEnd:arrowWhite,zIndex:100},

  {id: 'edge19', source: 'SpocB',sourceHandle: 'source-right-1', target: 'PlIM',targetHandle:'target-left-4', type:'customEdge',style: { stroke: 'white', strokeWidth:5},markerEnd:arrowWhite,zIndex:100, data:{power:telemetryDictionary['SPOC Power Plim']}},
  {id: 'edge20', source: 'PlIM',sourceHandle: 'source-right', target: 'Cam',targetHandle:'target-right', type:'customEdge',style: { stroke: 'white', strokeWidth:5},markerEnd:arrowWhite,zIndex:100, data:{power:telemetryDictionary['Cam On']}},


 ];

  const proOptions = { hideAttribution: true };
  

  return (
<ReactFlowProvider>
      <div className={reactAppWrapper} style={{ height, width }}>
        <ReactFlow
          onInit={onInit}
          nodes={nodes}
          edges={initialEdges}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          proOptions={proOptions}
          //defaultViewport={{ x: options.X, y: options.Y, zoom: options.Zoom }}
          minZoom={0.1}
          panOnDrag={true}
          zoomOnScroll={true}
          zoomOnPinch={true}
          zoomOnDoubleClick={true}
          fitView={true}
        />
      </div>
    </ReactFlowProvider>
  );

}

export default App;







