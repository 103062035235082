import React from 'react';
import { BaseEdge, EdgeLabelRenderer, EdgeProps, getSmoothStepPath } from 'reactflow';

export default function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerStart,
  markerEnd,
  data,
}: EdgeProps) {
    
    const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  let testColor;

if (data && data.power && data.power.telemetry) {
    if (data.power.telemetry === '1' || 
        data.power.telemetry === 'High' || 
        data.power.telemetry === 'On' || 
        data.power.telemetry.includes('On')) {
          testColor = 'green';
    } else {
      testColor = 'rgb(255,255,255)';
    }
} else if (data && data.current && data.current.telemetry) {
  if (id === 'ICB EMAG Edge' || id === 'EMag Pwr'){
    if (parseFloat(data.current.telemetry) > 10) {
      testColor = 'green';
    } else {
      testColor = 'white';
    }
  }else if (id === 'ICB EESA Power'){
    if (parseFloat(data.current.telemetry) > 20) {
      testColor = 'green';
    } else {
      testColor = 'white';
    }
  } else {
    testColor = 'white';
  }
}
else{
  testColor = 'white';
}

  const edgeStyle = {
    ...style,
    stroke: testColor,
  }

  let finaledge;

  // if (id === 'mNLPtoELP'){
  //   const edge = `M${sourceX} ${sourceY}L${sourceX-20} ${sourceY}L ${sourceX-23},${sourceY}Q ${sourceX-28},${sourceY} ${sourceX-28},${sourceY+5}L ${sourceX-28},${targetY-5}Q ${sourceX-28},${targetY} ${sourceX-33},${targetY}L${targetX+20} ${targetY}L${targetX} ${targetY}`;
  //   finaledge = edge;
  // }
  // else if (id === 'ELPtomNLP'){
  //   const edge = `M${sourceX} ${sourceY}L${sourceX-20} ${sourceY}L ${sourceX-43},${sourceY}Q ${sourceX-48},${sourceY} ${sourceX-48},${sourceY+5}L ${sourceX-48},${targetY-5}Q ${sourceX-48},${targetY} ${sourceX-53},${targetY}L${targetX+20} ${targetY}L${targetX} ${targetY}`;
  //   finaledge = edge;
  // }
  if (id === 'EMAGtoEMagBoard'){
    sourceY = sourceY + 20;
    targetY = targetY + 20;
    const edge = `M${sourceX} ${sourceY} L${targetX-115} ${sourceY}  Q ${targetX-110},${sourceY} ${targetX-110},${sourceY+5} L${targetX-110} ${targetY-5} Q ${targetX-110},${targetY} ${targetX-105},${targetY} L${targetX} ${targetY}`;
    finaledge = edge;
  }
  else if (id === 'ELP PIP1'){
    const edge = `M${sourceX}  ${sourceY}L ${targetX-75},${sourceY} Q ${targetX-80},${sourceY} ${targetX-80},${sourceY-5} L ${targetX-80},${targetY+5} Q ${targetX-80},${targetY} ${targetX-75},${targetY} L ${targetX},${targetY}`;
    finaledge = edge;
  }
  else if (id === 'ELP PIP1 Power'){
    const edge = `M${sourceX}  ${sourceY}L ${targetX-45},${sourceY} Q ${targetX-50},${sourceY} ${targetX-50},${sourceY-5} L ${targetX-50},${targetY+5} Q ${targetX-50},${targetY} ${targetX-45},${targetY} L ${targetX},${targetY}`;
    finaledge = edge;
  }
  else if (id === 'mNLPtoELP'){
    const edge = `M${sourceX}  ${sourceY}L ${sourceX-795}, ${sourceY}Q ${sourceX-800}, ${sourceY} ${sourceX-800},${sourceY+5}L ${sourceX-800},${targetY}Q ${sourceX-800},${targetY} ${sourceX-795},${targetY}L ${targetX},${targetY}`;
    finaledge = edge;
  }
  else if (id === 'ELPtomNLP'){
    const edge = `M${sourceX}  ${sourceY}L ${sourceX-755}, ${sourceY}Q ${sourceX-760}, ${sourceY} ${sourceX-760},${sourceY+5}L ${sourceX-760},${targetY}Q ${sourceX-760},${targetY} ${sourceX-755},${targetY}L ${targetX},${targetY}`;
    finaledge = edge;
  }
  else if (id === 'ELP PIP2'){
    const edge = `M${sourceX} ${sourceY}L ${sourceX},${sourceY-270}Q ${sourceX},${sourceY-275} ${sourceX+5},${sourceY-275}L ${targetX-5},${sourceY-275}Q ${targetX},${sourceY-275} ${targetX},${sourceY-280}L${targetX} ${targetY}`;
    finaledge = edge;
  }
  else if (id === 'ELP PIP2 Power'){
    const edge = `M${sourceX} ${sourceY}L ${sourceX},${sourceY-250}Q ${sourceX},${sourceY-255} ${sourceX+5},${sourceY-255}L ${targetX-5},${sourceY-255}Q ${targetX},${sourceY-255} ${targetX},${sourceY-260}L${targetX} ${targetY}`;
    finaledge = edge;
  }
  else if (id === 'ELP FPP'){
    const edge = `M${sourceX} ${sourceY}L ${sourceX},${sourceY-350}Q ${sourceX},${sourceY-355} ${sourceX+5},${sourceY-355}L ${targetX-5},${sourceY-355}Q ${targetX},${sourceY-355} ${targetX},${sourceY-360}L${targetX} ${targetY}`;
    finaledge = edge;
  }
  // else if (id === 'ELP FPP'){
  //   const edge = `M${sourceX}  ${sourceY}L ${sourceX-875}, ${sourceY}Q ${sourceX-880}, ${sourceY} ${sourceX-880},${sourceY-5}L ${sourceX-880},${targetY-45}Q ${sourceX-880},${targetY-50} ${sourceX-875},${targetY-50}L ${targetX-5},${targetY-50}Q  ${targetX},${targetY-50}  ${targetX},${targetY-45}L ${targetX} ${targetY}`;
  //   finaledge = edge;
  // }
  // else if (id === 'ELP FPP Power'){
  //   const edge = `M${sourceX}  ${sourceY}L ${sourceX-855}, ${sourceY}Q ${sourceX-860}, ${sourceY} ${sourceX-860},${sourceY-5}L ${sourceX-860},${targetY-25}Q ${sourceX-860},${targetY-30} ${sourceX-855},${targetY-30}L ${targetX-5},${targetY-30}Q  ${targetX},${targetY-30}  ${targetX},${targetY-25}L ${targetX} ${targetY}`;
  //   finaledge = edge;
  // }  
    else if (id === 'ELP FPP Power'){
      const edge = `M${sourceX} ${sourceY}L ${sourceX},${sourceY-330}Q ${sourceX},${sourceY-335} ${sourceX+5},${sourceY-335}L ${targetX-5},${sourceY-335}Q ${targetX},${sourceY-335} ${targetX},${sourceY-340}L${targetX} ${targetY}`;
      finaledge = edge;
  } 
  else if (id === 'ICB EESA Edge'){
    const edge = `M${sourceX} ${sourceY+25}L ${sourceX}, ${targetY-5}Q ${sourceX}, ${targetY-10} ${sourceX-5}, ${targetY-10}L ${targetX+5}, ${targetY-10}Q ${targetX}, ${targetY-10} ${targetX}, ${targetY-5}L${targetX} ${targetY}`;
    finaledge = edge;
  }
  else if (id === 'ICB EESA Power'){
    const edge = `M${sourceX} ${sourceY}L ${sourceX}, ${targetY-25}Q ${sourceX}, ${targetY-30} ${sourceX-5}, ${targetY-30}L ${targetX+5}, ${targetY-30}Q ${targetX}, ${targetY-30} ${targetX}, ${targetY-25}L${targetX} ${targetY+20}`;
    finaledge = edge;
  }
  else if (id === 'edge20'){
    const edge = `M${sourceX} ${sourceY}L ${sourceX+725},${sourceY}Q ${sourceX+730},${sourceY} ${sourceX+730},${sourceY-5}L ${sourceX+730}, ${targetY+5}Q ${sourceX+730}, ${targetY} ${sourceX+725}, ${targetY}L ${targetX}, ${targetY}`;
    finaledge = edge;
  }
  else{
    finaledge = edgePath;
  }

  return (
    <>
      <BaseEdge path={finaledge} markerStart={markerStart} markerEnd={markerEnd} style={edgeStyle} />
      <EdgeLabelRenderer>
        <div style={{
            position: 'absolute',
            transform: `translate(-50%, -100%) translate(${labelX}px,${labelY}px)`,
          }}
          className="nodrag nopan customEdge">
        </div>
      </EdgeLabelRenderer>
    </>
  );

}
