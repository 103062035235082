import React from 'react';
import { css } from '@emotion/css';
import { Handle, Position } from 'reactflow';
import { TbProps } from 'components/utils';

const ICB: React.FC<TbProps> = ({ data }) => {
  const containerStyle = css`
    width: 950px;
    height: 900px;
    border: 5px solid rgb(0, 0, 0);
    background-color: rgb(92, 60, 18);
    position: relative;
    text-align: center;

    // &::after {
    //   content: "";
    //   width: 900px;
    //   height: 275px;
    //   position: absolute;
    //   top: 0;
    //   right: 0;
    //   border-left: 5px solid rgb(0, 0, 0);
    //   border-top: 5px solid rgb(0, 0, 0);
    // }

    // &::before {
    //   content: "";
    //   width: 50px;
    //   height: 625px;
    //   position: absolute;
    //   left: 0;
    //   bottom: 0;
    //   border-left: 5px solid rgb(0, 0, 0);
    //   border-top: 5px solid rgb(0, 0, 0);
    // }
  `;

  return (
    <div className={containerStyle}>
      <Handle type="target" position={Position.Top} id="target-top" style={{ opacity: 0 }} />
      <Handle type="target" position={Position.Left} id="target-left" style={{ opacity: 0 }} />
      <Handle type="target" position={Position.Right} id="target-right" style={{ opacity: 0 }} />
      <Handle type="target" position={Position.Bottom} id="target-bottom" style={{ opacity: 0 }} />
      <Handle type="source" position={Position.Top} id="source-top" style={{ opacity: 0 }} />
      <Handle type="source" position={Position.Top} id="source-top-1" style={{ opacity: 0, left: 850 }} />
      <Handle type="source" position={Position.Top} id="source-top-2" style={{ opacity: 0, left: 900 }} />
      <Handle type="source" position={Position.Top} id="source-top-3" style={{ opacity: 0, left: 550 }} />
      <Handle type="source" position={Position.Top} id="source-top-4" style={{ opacity: 0, left: 570 }} />
      <Handle type="source" position={Position.Left} id="source-left" style={{ opacity: 0 }} />
      <Handle type="source" position={Position.Left} id="source-left-1" style={{ opacity: 0, top: 450 }} />
      <Handle type="source" position={Position.Left} id="source-left-2" style={{ opacity: 0, top: 430 }} />
      <Handle type="source" position={Position.Left} id="source-left-3" style={{ opacity: 0, top: 500 }} />
      <Handle type="source" position={Position.Left} id="source-left-4" style={{ opacity: 0, top: 520 }} />
      <Handle type="source" position={Position.Right} id="source-right" style={{ opacity: 0 }} />
      <Handle type="source" position={Position.Right} id="source-right-1" style={{ opacity: 0, top: 100 }} />
      <Handle type="source" position={Position.Right} id="source-right-2" style={{ opacity: 0, top: 150 }} />
      <Handle type="source" position={Position.Bottom} id="source-bottom" style={{ opacity: 0 }} />
      <Handle type="source" position={Position.Bottom} id="source-bottom-1" style={{ left: 430 }} />

      <p>Instrument Control(ICB)</p>
    </div>
  );
};

export default ICB;
