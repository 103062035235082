import { css } from '@emotion/css';
import React from 'react'
import { Handle, Position } from 'reactflow';

interface EIDPUProp {
  data: {
    power: {
      telemetry: string;
      unit: string;
      limit: string;
      live: string;
    };
  };
}

const EIDPU: React.FC<EIDPUProp> = ({data}) => {

  const getColor2 = (value: string) => {
    if (!value) return "black"; // default color for null or undefined
    switch (value) {
      case 'High':
        return "green";
      case 'Low':
        return "black";
      default:
        return "black"; // default color for null or undefined
    }
  };

  const eidpuContainer = css`
  width: 1650px;
  height: 975px;
  background-color: rgb(22, 65, 108);
  border: 5px solid ${getColor2(data.power?.telemetry ?? 'black')};
`;


  return (
    <div className={eidpuContainer}>
      <Handle type="target" position={Position.Top} id="target-top" style={{opacity:0}}/>
      <Handle type="target" position={Position.Top} id="target-top-1" style={{opacity:0,left:1011}}/>
      <Handle type="target" position={Position.Left} id="target-left" style={{opacity:0}}/>
      <Handle type="target" position={Position.Left} id="target-left-1" style={{opacity:0,top:1300}}/>
      <Handle type="target" position={Position.Right} id="target-right" style={{opacity:0}}/>
      <Handle type="target" position={Position.Bottom} id="target-bottom" style={{opacity:0}}/> 
      <Handle type="source" position={Position.Top} id="source-top" style={{opacity:0}}/>
      <Handle type="source" position={Position.Left} id="source-left" style={{opacity:0}}/>
      <Handle type="source" position={Position.Right} id="source-right" style={{opacity:0}}/>
      <Handle type="source" position={Position.Bottom} id="source-bottom" style={{opacity:0}}/>

      <p>Instrument Data Processing Unit(EIDPU)</p>

    </div>
  )
}

export default EIDPU;
