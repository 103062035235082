import React from "react";
import { TLM } from "./utils";
import { css } from '@emotion/css';

interface HeaterProp {
  data: {
    name: string;
    priArm: TLM;
    secArm: TLM;
    priFire: TLM;
    secFire: TLM;
  };
}

const BoomPlug: React.FC<HeaterProp> = ({ data }) => {

  const boomGrid = css`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 10px;
    width: 180px;
    height: 90px;
    justify-items: center;
    align-items: center;
  `;

  const labelStyle = css`
    grid-column: span 1;
    text-align: center;
  `;

  const getColor = (value: TLM) => {
    if (value && value.telemetry === "High") {
      return "green";
    } else {
      return "rgb(100,100,100)";
    }
  };

  return (
    <div className={boomGrid}>
      <div className={labelStyle}></div>
      <div className={labelStyle}>Arm</div>
      <div className={labelStyle}>Fire</div>

      <div className={labelStyle}>Pri</div>
      <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="-0.5 -0.5 30 30">
        <ellipse cx="15" cy="15" rx="13" ry="13" stroke="rgb(38,38,38)" fill={getColor(data.priArm)} strokeWidth="4"/>
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="-0.5 -0.5 30 30">
        <ellipse cx="15" cy="15" rx="13" ry="13" stroke="rgb(38,38,38)" fill={getColor(data.priFire)} strokeWidth="4"/>
      </svg>

      <div className={labelStyle}>Sec</div>
      <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="-0.5 -0.5 30 30">
        <ellipse cx="15" cy="15" rx="13" ry="13" stroke="rgb(38,38,38)" fill={getColor(data.secArm)} strokeWidth="4"/>
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="-0.5 -0.5 30 30">
        <ellipse cx="15" cy="15" rx="13" ry="13" stroke="rgb(38,38,38)" fill={getColor(data.secFire)} strokeWidth="4"/>
      </svg>
    </div>
  );
};

export default BoomPlug;
