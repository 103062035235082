import React from "react";

interface NameProp {
  data: {
    name: string;
  };
}

const Name: React.FC<NameProp> = ({ data }) => {

  return (
    <div >

      <span>{data.name}</span>

    </div>
  );
};

export default Name;
