import { css } from '@emotion/css';
export const plimBox = css`
  width: 1700px;

  & > div {
    width:1700;
    height: 200px;

    & > div {
      width: 520px;

      &:nth-of-type(1) {
        width: 380px;
      }

      &:nth-of-type(3) {
        width: 340px;

        & > div:nth-of-type(2){
          width: 150px;
        }
      }

      &:nth-of-type(4) {
        width: 270px;

        & > div:nth-of-type(2){
          width: 150px;
        }
      }

      &:nth-of-type(2),
      &:nth-of-type(5) {
        width: 280px;

        & > div:nth-of-type(2){
          width: 150px;
        }
      }

      &:nth-of-type(6) {
        width: 280px;

        & > div:nth-of-type(2){
          width: 150px;
        }
      }

      &:nth-of-type(7) {
        width: 340px;

        & > div:nth-of-type(2){
          width: 150px;
        }
      }

      &:nth-of-type(8) {
        width: 360px;

        & > div:nth-of-type(2){
          width: 150px;
        }
      }

      &:nth-of-type(9) {
        width: 400px;

        & > div:nth-of-type(2){
          width: 150px;
        }
      }

      &:nth-of-type(10) {
        width: 310px;

        & > div:nth-of-type(2){
          width: 150px;
        }
      }

      &:nth-of-type(11) {
        width: 460px;

        & > div:nth-of-type(2){
          width: 180px;
        }
      }

      &:nth-of-type(12) {
        width: 460px;

        & > div:nth-of-type(2){
          width: 150px;
        }
      }

      &:nth-of-type(13) {
        width: 450px;

        & > div:nth-of-type(2){
          width: 150px;
        }
      }

    }
  }
`;

export const boomPlugBox = css`
width: 720px;

  & > div {
    width:720px;
    height: 200px;

    & > div {
      width: 350px;

      & div:nth-of-type(2) {
        width: 150px;
      }
    }
  }
`;

export const fluxgateEmag = css`
  width: 720px;

  // & p{
  //   color: green;
  // }

  & > div {
    width:720px;
    height: 360px;
    flex-direction: column;

    & > div {
      width: 410px;

      & > div:nth-of-type(2) {
        width: 150px;
      }
    }

    & > div {
      &:nth-of-type(1){
        width: 300px;
      }
    }

    & > div {
      &:nth-of-type(2){
        width: 300px;
      }
    }

    & > div {
      &:nth-of-type(3){
        width: 300px;
      }
    }

    & > div {
      &:nth-of-type(4){
        width: 300px;
      }
    }
    & > div {
      &:nth-of-type(5){
        width: 300px;
      }
    }
    & > div {
      &:nth-of-type(6){
        width: 300px;
      }
    }

  }
`;

// Define a type for the class keys
export type ClassKey = 'plimBox' | 'boomPlugBox' | 'fluxgateEmag';