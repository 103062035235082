import { css, cx } from '@emotion/css';
import * as scheme from './Scheme'; // Import your styl
import React, { useState } from 'react'
import { Handle, Position } from 'reactflow';
import { TbProps, VisibleTooltips, determineColorNew } from './utils';
import PlotlyChart from './PlotlyChart';
import copy from 'copy-to-clipboard';

const Spoc: React.FC<TbProps> = ({data}) => {

  const [visibleTooltips, setVisibleTooltips] = useState<VisibleTooltips>({});

  const handleMneNameClick = (key: string) => {
    setVisibleTooltips(prevVisibleTooltips => ({
      ...prevVisibleTooltips,
      [key]: true
    }));
  
    setTimeout(() => {
      setVisibleTooltips(prevVisibleTooltips => ({
        ...prevVisibleTooltips,
        [key]: false
      }));
    }, 5000);
  };

  const handleCopyMneName = (mne: string) => {
    copy(mne);
  };

  const [visibleLimitPops, setVisibleLimitPops] = useState<VisibleTooltips>({});

  const handleValueChartClick = (key: string) => {
    setVisibleLimitPops(prevVisibleLimitPops => ({
      ...prevVisibleLimitPops,
      [key]: !prevVisibleLimitPops[key]
    }));
  };

  const getColor = (self: string, other:string) => {
    const selfN = parseFloat(self);
    const otherN = parseFloat(other);
    if (selfN > otherN) {
        return "rgb(72, 200, 44)";
    }
    else{
      return "rgb(100,100,100)";
    }
  }

  const [showPowerInfo, setShowPowerInfo] = useState(false);
  const [showPowerInfo1, setShowPowerInfo1] = useState(false);

  const handlePowerEllipseClick = () => {
    setShowPowerInfo(!showPowerInfo);

    setTimeout(() => {setShowPowerInfo(false)}, 2000);
  };

  const handlePowerEllipseClick1 = () => {
    setShowPowerInfo1(!showPowerInfo1);

    setTimeout(() => {setShowPowerInfo1(false)}, 2000);
  };

  const svgContainer = css`
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
  margin: 0px 0px;
  height: 55px;
  `;

  const svgInnerContainer = css`
  display: flex;
  `;


  return (
    <div>
      <Handle type="target" position={Position.Top} id="target-top" style={{opacity:0}}/>
      <Handle type="target" position={Position.Left} id="target-left" style={{opacity:0}}/>
      <Handle type="target" position={Position.Right} id="target-right" style={{opacity:0}}/>
      <Handle type="target" position={Position.Right} id="target-right-4" style={{top:200, opacity:0}}/>
      <Handle type="target" position={Position.Bottom} id="target-bottom" style={{opacity:0}}/>
      <Handle type="source" position={Position.Bottom} id="source-bottom" style={{left:150, opacity:0}}/>
      
      <Handle type="source" position={Position.Top} id="source-top" style={{opacity:0}}/>
      <Handle type="source" position={Position.Left} id="source-left" style={{opacity:0}}/>
      <Handle type="source" position={Position.Right} id="source-right" style={{opacity:0}}/>
      <Handle type="source" position={Position.Right} id="source-right-1" style={{opacity:0, top:80}}/>
      <Handle type="source" position={Position.Bottom} id="source-bottom" style={{opacity:0}}/>

      <div className={cx(scheme.textboxContainer)}>

      <p>{data.name}</p>

      <div className={svgContainer}>

      <p>Active Spoc</p>

      <div className={svgInnerContainer}>

      {data.pripwr && <div>
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width="100px" height="40px" viewBox="-0.5 -0.5 100 40" onClick={handlePowerEllipseClick}>
          <g>
            <g>
            <rect x="5" y="5" width="90" height="30" fill="rgb(38,38,38)" stroke={getColor(data.pripwr.telemetry, data.secpwr.telemetry)} pointerEvents="all" strokeWidth="6"/>
            </g>
            <g>
              <text x="50" y="30" fill={getColor(data.pripwr.telemetry, data.secpwr.telemetry)} fontFamily="Times New Roman" fontSize="30px" textAnchor="middle">A
              </text>
            </g>
          </g>
        </svg>
            {/* {showPowerInfo && <div className={scheme.svgPopValue}>
              <div className={scheme.svgValueSpanContainer}>
                <span className={scheme.svgKey} onClick={() => handleMneNameClick("PriPower")}>
                  Pri
                  <span className={scheme.svgFnameTooltip}>Pri Power</span>
                  {visibleTooltips['PriPower'] && <span className={scheme.mnameTooltip}>{data.pripwr.mne}</span>}
                </span>
                <span 
                className={cx(
                  scheme.svgValue,
                  css`color: ${data.pripwr.live}`,
                  scheme.classMap[determineColorNew(data.pripwr.limit)]
                )}
              >
                {data.pripwr.telemetry}
              </span>
              </div>
            </div>} */}
            </div>}

            {data.secpwr && <div>
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width="100px" height="40px" viewBox="-0.5 -0.5 100 40" onClick={handlePowerEllipseClick1}>
          <g>
            <g>
            <rect x="5" y="5" width="90" height="30" fill="rgb(38,38,38)" stroke={getColor(data.secpwr.telemetry, data.pripwr.telemetry)} pointerEvents="all" strokeWidth="6"/>
            </g>
            <g>
              <text x="50" y="30" fill={getColor(data.secpwr.telemetry, data.pripwr.telemetry)} fontFamily="Times New Roman" fontSize="30px" textAnchor="middle">B
              </text>
            </g>
          </g>
        </svg>
            {/* {showPowerInfo1 && <div className={scheme.svgPopValue}>
              <div className={scheme.svgValueSpanContainer}>
                <span className={scheme.svgKey} onClick={() => handleMneNameClick("SecPower")}>
                  Sec
                  <span className={scheme.svgFnameTooltip}>Sec Power</span>
                  {visibleTooltips['SecPower'] && <span className={scheme.mnameTooltip}>{data.secpwr.mne}</span>}
                </span>
                <span 
                className={cx(
                  scheme.svgValue,
                  css`color: ${data.secpwr.live}`,
                  scheme.classMap[determineColorNew(data.secpwr.limit)]
                )}
              >
                {data.secpwr.telemetry}
              </span>
              </div>
            </div>} */}
            </div>}
            </div>
            </div>

<div className={scheme.textboxItems}>
  
{data.value && Object.entries(data.value).map(([key, innerData]) => (
    <div className={scheme.tbValueContainer} key={key}>
      <div className={scheme.FnameKeyContainer} onClick={() => handleMneNameClick(key)}>
        {key}:
        {visibleTooltips[key] && <span className={scheme.mnameTooltip} onClick={() => handleCopyMneName(innerData.dbData.mne)}>{innerData.dbData.mne}</span>}
        <span data-comp="fname" className={scheme.tbFnameTooltip}>{innerData.fname}</span>
      </div>
      <div className={scheme.tbValueSpanContainer} onClick={() => handleValueChartClick(key)}>
        <span 
          className={cx(
            scheme.value, 
            css`color: ${innerData.dbData && innerData.dbData.live ? innerData.dbData.live : ""}`,
            scheme.classMap[determineColorNew(innerData.dbData?.limit)], 
          )}
        >
          {innerData.dbData?.telemetry}
        </span>
        <span className="separator"></span>
        {innerData.dbData && innerData.dbData.unit && <span className={scheme.svgUnit}>{innerData.dbData.unit}</span>}
      </div>
      {visibleLimitPops[key] && 
        <div className={scheme.chartContainer}>
          <PlotlyChart data={{ mne: innerData.dbData.mne, sc: innerData.dbData.spacecraft }}></PlotlyChart>
          <button className={scheme.plotButton} onClick={() => handleValueChartClick(key)}>
            Close Plot
          </button>
        </div>}
    </div>
  ))}
</div>
      </div>
    </div>
  )
}

export default Spoc;
