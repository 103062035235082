export type InnerDataValue = {
  telemetry: number;
  unit: string;
  limit: LimitProp;
  fname: string;
  mname: string;
  live: string;
  value_color: string;
  dbData: Dbdata;
  spacecraft: string;
};

export type InnerData = {
  [key: string]: InnerDataValue;
};

export interface LimitProp{
  rl: number | null;
  yl: number | null;
  yh: number | null;
  rh: number | null;
}

export type VisibleTooltips = { [key: string]: boolean };

export interface Dbdata{
  telemetry: number;
  unit: string;
  //limit: LimitProp;
  limit: string;
live: string;
mne: string;
spacecraft: string;
}

export interface TbProps {
    data: {
      name: string;
      value: InnerData;
      textbox_item_class: string;
      textbox_container_class: string;
      id: string;
      backgroundImage: string;
      power: TLM;
      power1: TLM;
      pripwr: TLM;
      secpwr: TLM;
      powerName: string;
      power1Name: string;
      current: TLM;
    };
}

export interface TLM {
  telemetry: string;
  unit: string;
  limit: string;
  live: string;
  mne: string;
}

export type Field = {
  name: string;
  type: string;
  labels?: { [key: string]: string };
  values: any[]; // Replace 'any' with a more specific type if possibl
};

export interface ProcessedData {
  [key: string]: { cnvValue: string, mne: string, name: string }[];
}

export interface DataObject {
  name: string;
  refId: string;
  length: number;
  fields: Field[];
  labels: { [key: string]: string };
}

export function lookUpName(mne: string) {
  for (const [key, value] of Object.entries(nameToMne)) {
    if (value === mne) {
      return key;
    }
  }
  return "NoName"; // Return null if the value is not found
}

export const nameToMne = {

  /*EESA*/
  "Erawi": "apid_0x124.mne.erawi", /*electron Raw HV current*/
  "Irawi": "apid_0x124.mne.irawi", /*ion Raw HV current*/
  "Vmon eraw": "apid_0x124.mne.erawv", /*electron Raw HV voltage*/
  "Vmon iraw": "apid_0x124.mne.irawv", /*ion Raw HV voltage*/

  /* Current Checked */
  "Imon Ref": "apid_0x10e.mne.imonref", /*Reference current monitor as reported by the ICB*/

  /* EMAG Checked*/
  "EMag Power": "apid_0x119.mne.emag_pwr_on", /*EMAG instrument power state. ON=1, OFF=0*/
  "EMag p15i": "apid_0x10e.mne.mag_p15i",  /*EMAG +15V current monitor as reported by the ICB*/
  "EMag n15i": "apid_0x10e.mne.mag_n15i", /*EMAG -15V current monitor as reported by the ICB*/
  "EMag p3_3i": "apid_0x10e.mne.mag_p3_3i", /*EMAG +3.3V current monitor as reported by the ICB*/

  "Emag Vmon p15": "apid_0x119.mne.p15v", /*EMAG +15V Voltage Monitor as reported by the EMAG instrument*/
  "Emag Vmon n15": "apid_0x119.mne.n15v", /*EMAG -15V Voltage Monitor as reported by the EMAG instrument*/
  "Emag Vmonm p3_3": "apid_0x119.mne.p3_3v", /*EMAG +3.3V Voltage Monitor as reported by the EMAG instrument*/
  "Emag Vmon p2_5": "apid_0x119.mne.p2_5v", /*EMAG +2.5V Voltage Monitor as reported by the EMAG instrument*/
  "Emag Vmon n8_3": "apid_0x119.mne.n8_3v", /*EMAG -8.3V Voltage Monitor as reported by the EMAG instrument*/
  "Vmon p5": "apid_0x119.mne.p5v", /*+EMAG +5V Voltage Monitor as reported by the EMAG instrument*/

  "Vehicle Mode Agg":"state_spinand0_last_cmd_vehicle_mode_agg",

  "Sensor Temp": "apid_0x119.mne.sensor_temp", /*EMAG sensor temperature as reported by the EMAG instrument*/
  "Pcb Temp": "apid_0x119.mne.pcb_temp", /*EMAG PCB temperature as reported by the EMAG instrument*/

  /* ELP Checked*/
  "ELP Power": "apid_0x112.mne.elp_pwr_on", /*ELP instrument power state. ON=1, OFF=0*/
  "ELP n15i": "apid_0x10e.mne.elp_n15i", /*ELP -15V current monitor as reported by the ICB*/
  "ELP p15i": "apid_0x10e.mne.elp_p15i", /*ELP +15V current monitor as reported by the ICB*/
  "ELP p3_3i": "apid_0x10e.mne.elp_p3_3i", /*ELP +3.3V current monitor as reported by the ICB*/
  "ELP p15v": "apid_0x112.mne.p15v", /* +15V voltage monitor as reported by the ELP instrument*/
  "ELP n15v": "apid_0x112.mne.n15v", /* -15V voltage monitor as reported by the ELP instrument*/
  "ELP p5v": "apid_0x112.mne.p5v", /* +5V voltage monitor as reported by the ELP instrument*/
  "ELP p3_3v": "apid_0x112.mne.p3_3v", /* +3.3V voltage monitor as reported by the ELP instrument*/

  "TL Pip Temp": "apid_0x112.mne.temp_tl_pip", /*ELP top left temperature as reported by the ELP instrument*/
  "Tr Adc Temp": "apid_0x112.mne.temp_tr_adc", /*ELP top right temperature as reported by the ELP instrument*/
  "LL mNLP Temp": "apid_0x112.mne.temp_ll_mnlp", /*ELP lower left temperature as reported by the ELP instrument*/

  /* EESA Checked*/
  "EESA Power": "apid_0x124.mne.eesa_pwr_on", /*EESA instrument power state. ON=1, OFF=0*/
  "EESA n15i": "apid_0x10e.mne.eesa_n15i", /*EESA -15V current monitor as reported by the ICB*/
  "EESA p15i": "apid_0x10e.mne.eesa_p15i", /*EESA +15V current monitor as reported by the ICB*/
  "EESA p3_3i": "apid_0x10e.mne.eesa_p3_3i", /*EESA +3.3V current monitor as reported by the ICB*/
  "EESA 28i": "apid_0x10e.mne.eesa_28i", /*EESA +28V current monitor as reported by the ICB*/
  "Iana Temp": "apid_0x124.mne.ianalt", /*ion Analyzer temp*/
  "Eanal Temp": "apid_0x124.mne.eanalt", /*electron Analyzer temp*/
  "Eandoet Temp": "apid_0x124.mne.eanodet", /*EESA eANODE Temp*/
  "Digital Temp": "apid_0x124.mne.digitalt", /*EESA Digital Temp*/

  /* Voltage Checked*/
  "Vmon 28": "apid_0x10e.mne.vmon28", /*+28V voltage monitor as reported by the ICB*/
  "Vmon 15": "apid_0x10e.mne.vmon15", /*+15V voltage monitor as reported by the ICB*/
  "Vmon 3_3": "apid_0x10e.mne.vmon_3_3", /*+3.3V voltage monitor as reported by the ICB*/
  "Vmonm 15": "apid_0x10e.mne.vmonm_15", /*-15V voltage monitor as reported by the ICB*/
  "Vmon Core": "apid_0x10e.mne.vmoncore", /*Core voltage monitor as reported by the ICB*/


  /* Temperature */
  "ICB Temp": "apid_0x10e.mne.icbt", /*ICB temperature monitor as reported by the ICB*/

  /* Boom */
  "Boom Arm": "eps_plim_dio_act_pri_arm_en", /*Boom Pri Act Arm*/
  "Boom Fire": "eps_plim_dio_act_pri_fire_on", /*Boom Pri Act Fire*/
  "Boom Sec Arm": "eps_plim_dio_act_sec_arm_en", /*Boom Sec Act Arm*/
  "Boom Sec Fire": "eps_plim_dio_act_sec_fire_on", /*Boom Sec Act Fire*/
  
  /* Command Interface */
  "Gnd Cmd cnt": "apid_0x10f.mne.gnd_cmd_cnt", /*Total number of Ground commands received from the IDPU SC interface.*/
  "Sc Cmd cnt": "apid_0x10f.mne.sc_cmd_cnt", /*Total number of Spacecraft-generated commands received from the IDPU SC interface.*/
  "CRC error": "apid_0x104.mne.crc_errors", /*Total number of CRC errors detected by the command parser*/
  "Cmd Accept cnt": "apid_0x119.mne.cmd_accept_cnt", /*Command Accept Counter as reported by the EMAG instrument*/
  "Cmd Reject cnt": "apid_0x119.mne.cmd_reject_cnt", /*Command Reject Counter as reported by the EMAG instrument*/
  "Cmd Received cnt": "apid_0x141.mne.cmds_received", /*See svn.ssl.berkeley.edu/escapade/05-Instruments/5.1-EESA/EESA_CTM.xlsx for details.*/

    /* SW Version */
  "Board ID": "apid_0x109.mne.board_id", /*Board identifier.*/
  "FSW major": "apid_0x109.mne.fsw_major", /*FSW major version. FSW version adheres to Semantic Versioning (semver.org). Major number is incremented when new backwards incompatible changes are added to the FSW.*/
  "FSW minor": "apid_0x109.mne.fsw_minor", /*FSW minor version. FSW version adheres to Semantic Versioning (semver.org). Minor number is incremented when new backwards compatible features are added to the FSW*/
  "FSW Patch": "apid_0x109.mne.fsw_patch", /*FSW patch version. FSW version adheres to Semantic Versioning (semver.org). Patch number is incremented when new backwards compatible bug fixes are added to the FSW*/

  /* Pulse Per Second ICB */
  "PPS Mode": "apid_0x119.mne.pps_mode", /*(1=internal,0=auto)*/
  "Missed PPS": "apid_0x116.mne.missed_sc_pps", /*"Number of times the Spacecraft PPS signal has been missed, i.e. not received during the expected PPS window. PPS window size is determined by the backup PPS generator period, see time_setup_bkp_pps() for more info. This value is also incremented by one every second if the FSW has been instructed to ignore the SC PPS signal (via time_setup_bkp_pps(â€¦, ign_sc_pps=True) command)"*/
  "Irregular PPS": "apid_0x116.mne.irregular_sc_pps_cnt", /*"Number of times the ICB has detected an irregular SC PPS. A PPS qualifies as irregular when the delta with the previous PPS is not in the 1s +/- 30us range."*/
  "PPS delta": "apid_0x116.mne.sc_pps_delta", /*"Delta between the last PPS and the previous one. Measured in cycles, with a timer running at 65217Hz. This value is only valid when ign_sc_pps is False and the bkp_pps_period is >= 65282."*/
  "Range Mode": "apid_0x119.mne.range_mode", /* EMAG 0,1=auto, 2=manual 0, 3=manual 1*/

  /* Heater */
  "EIDPU State": "tcs_thermostats_eidpu_htr_state", /* The current heater state. */
  "EESA State": "tcs_thermostats_eesa_htr_state", /* The current heater state. */
  "EMAG State": "tcs_thermostats_e_mag_htr_state", /* The current heater state. */

  /* Secondary */
  "Plim ISOi": "EPS_PLIM_POWER_RAIL_SENSE_28V_ISO_I_agg", /* */
  "Uptimes": "cdh_plim_uptime_ms", /* time since boot */
  
  "Boom Temp": "tcs_supervisor_boom_actuator_t_raw_agg", /* Boom temperature */

  "SPOC Power Plim": "EPS_SPOC_DIO_PLIM_MAIN_PWR_EN_agg", /* to tell if the PLIM is being powered by the SPOC. Can use as the PLIM power indicator */
  "Plim Pwri": "eps_spoc_power_plim_i", /* current reported by the PLIM */
  "PLIM Power EIDPU": "eps_plim_dio_m_pl_pri_pwr_en", /* to tell if the PLIM is providing power to the EIDPU */

  "EESAi attn mode change": "apid_0x122.mne.attn_mode_change_cnt", /* Number of times the EESAi has changed its attention mode */
  "EESAi attn MisMatch Cnt": "apid_0x122.mne.attn_state_mismatch_cnt", /* EESAi attention mode */
  "EESAi attn state": "apid_0x141.mne.act_status1", /* EESAi attention state */
  "Plim Pri En":"EPS_PLIM_DIO_M_PL_PRI_PWR_EN", /* */
  "Plim Sec En":"EPS_PLIM_DIO_M_PL_SEC_PWR_EN", /* */
  "Plim Pri":"eps_plim_dio_m_pl_pri_pwr_on", /* */
  "Plim Sec":"eps_plim_dio_m_pl_sec_pwr_on", /* */
  "Spoc Uptime":"CDH_SPOC_UPTIME_agg", /* */
  "Spoc A Volt":"EPS_SUPERVISOR_POWER_RAIL_SPOCA_VTLM_agg", /* */
  "Spoc B Volt":"EPS_SUPERVISOR_POWER_RAIL_SPOCB_VTLM_agg", /* */
  "Spoc A Curr":"EPS_SUPERVISOR_POWER_RAIL_SPOCA_CURR_TLM_agg", /* */
  "Spoc B Curr":"EPS_SUPERVISOR_POWER_RAIL_SPOCB_CURR_TLM_agg", /* */
  "Spoc Select":"eps_supervisor_dio_spoc_select", /* */
  "Cam On":"eps_plim_dio_cam_pwr_on", /* */
  "Cam Curr":"eps_plim_power_rail_sense_cam_i", /* */
  "Cam Volt":"eps_plim_power_rail_sense_cam_v", /* */
  "Cam Temp":"tcs_spoc_cptn_cam_t_raw_agg", /* */
  "EESA HV Status":"apid_0x141.mne.hv_status", /* */

  "EESAi Accelv":"apid_0x124.mne.iaccelv", /* */
  "EESAi Hemiv":"apid_0x124.mne.ihemiv", /* */
  "EESAi def1v":"apid_0x124.mne.idef1v", /* */
  "EESAi def2v":"apid_0x124.mne.idef2v", /* */
  "EESAi SpoielrV":"apid_0x124.mne.ispoilerv", /* */
  "EESAi McpV":"apid_0x124.mne.imcpv", /* */

  "EESAe Hemiv":"apid_0x124.mne.ehemiv", /* */
  "EESAe def1v":"apid_0x124.mne.edef1v", /* */
  "EESAe def2v":"apid_0x124.mne.edef2v", /* */
  "EESAe SpoielrV":"apid_0x124.mne.espoilerv", /* */
  "EESAe McpV":"apid_0x124.mne.emcpv", /**/

}



export function determineColor(value: number, limit: LimitProp){
  if (limit.rl !== null && value < limit.rl){
    return 'red-limit';
  }else if (limit.rh !== null && value > limit.rh){
    return 'red-limit';
  }else if (limit.rl !== null && limit.yl !== null && value >= limit.rl && value < limit.yl) {
    return 'yellow-limit';
  }else if (limit.yh !== null && limit.rh !== null && value >= limit.yh && value < limit.rh) {
    return 'yellow-limit';
  }else if (limit.yl !== null && limit.yh !== null && value >= limit.yl && value < limit.yh) {
    return 'green-limit';
  }else if (limit.rl !== null && limit.rh !== null && value >= limit.rl && value < limit.rh){
    return 'green-limit';
  }else{
    return 'gray-limit';
  }
}

export function determineColorNew(name: string){

if (name === 'rl' || name === 'rh' || name === 'RH' || name === 'RL'){
  return 'red-limit';
}
else if (name === 'yl' || name === 'yh' || name === 'YH' || name === 'YL'){
    return 'yellow-limit';
}else{
  return 'gray-limit';
}
}