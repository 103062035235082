import { css } from '@emotion/css';
import React from 'react'
import { Handle, Position } from 'reactflow';
import logo from '../img/Boom.png';

interface BoomProp {
  data: {
    deploy: boolean;
  };
}

const Boom: React.FC<BoomProp> = ({data}) => {

  const depoly = data.deploy;

  const boomContainer = css`
  width: 850px;
  height: 700px;
  border: 5px dashed rgb(33, 110, 224);
  position: relative;
`;

  const boomImg = css`
  position: absolute;
  left: 0; /* Position image at the very left */
  top: 0; /* Position image at the top */
  `;

  const boomImgDeploy = css`
  position: absolute;
  left: 0; /* Position image at the very left */
  top: 0; /* Position image at the top */
  transform: rotate(-7deg) translateX(-50px);
  `;


  return (
    <div className={boomContainer}>

      <Handle type="target" position={Position.Top} id="target-top" style={{opacity:0}}/>
      <Handle type="target" position={Position.Top} id="target-top-1" style={{left:1500}}/>
      <Handle type="target" position={Position.Left} id="target-left" style={{opacity:0}}/>
      <Handle type="target" position={Position.Left} id="target-left-1" style={{top:1300}}/>
      <Handle type="target" position={Position.Right} id="target-right" style={{opacity:0}}/>
      <Handle type="target" position={Position.Bottom} id="target-bottom" style={{opacity:0}}/> 
      <Handle type="source" position={Position.Top} id="source-top" style={{opacity:0}} />
      <Handle type="source" position={Position.Left} id="source-left" style={{opacity:0}}/>
      <Handle type="source" position={Position.Right} id="source-right" style={{opacity:0}}/>
      <Handle type="source" position={Position.Bottom} id="source-bottom" style={{opacity:0}}/>

      {/* <span>Boom</span> */}

      {!depoly && <img className={boomImg} src={logo} alt="Boom" height={700}/>}
      {depoly && <img className={boomImgDeploy} src={logo} alt="Boom" height={700}/>}
      

    </div>
  )
}

export default Boom;
