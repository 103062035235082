import { css } from '@emotion/css';
export const EmagBoard = css`
  width: 380px;
  background-color: rgb(92, 60, 18);

  & > div {

    & > div {

      & > div:nth-of-type(2){
      }
    }
  }

`;

export const ElpBoard = css`
  width: 620px;
  background-color: rgb(92, 60, 18);

  & > div {
    width:620px;
    height: 300px;
    flex-wrap: wrap;

    & > div {
      width: 300px;

      & > div:nth-of-type(2){
        width: 150px;
      }
    }
  }
`;

export const icbEesa = css`
  width: 400px;
`;

export const pncMonitor = css`
  width: 420px;
`;

export const ppsBox = css`
  width: 420px;

  & > div {

    & > div {

      & > div:nth-of-type(2){
        width: 150px;
      }
    }
  }
`;

export const cmdInter = css`
  width: 440px;

  & > div {

    & > div {

      & > div:nth-of-type(2){
        width: 150px;
      }
    }
  }
`;


// Define a type for the class key
export type ClassKey = 'EmagBoard' | 'icbEesa' | 'ElpBoard' | 'pncMonitor' | 'ppsBox' | 'cmdInter';