import * as scheme from './Scheme'; // Import your styles
import React, { useState } from 'react'
import { InnerData, determineColorNew} from "./utils";
import { Handle, Position } from "reactflow";
import logo from '../img/Cam.png';
import PlotlyChart from './PlotlyChart';
import { css, cx } from '@emotion/css';
import copy from 'copy-to-clipboard';

type VisibleTooltips = { [key: string]: boolean };

interface CamProp {
  data: {
    value: InnerData;
  };
}

const Cam: React.FC<CamProp> = ({ data }) => {

  const [visibleTooltips, setVisibleTooltips] = useState<VisibleTooltips>({});

  const handleMneNameClick = (key: string) => {
    setVisibleTooltips(prevVisibleTooltips => ({
      ...prevVisibleTooltips,
      [key]: true
    }));
  
    setTimeout(() => {
      setVisibleTooltips(prevVisibleTooltips => ({
        ...prevVisibleTooltips,
        [key]: false
      }));
    }, 5000);
  };

  const [visibleLimitPops, setVisibleLimitPops] = useState<VisibleTooltips>({});

  const handleValueChartClick = (key: string) => {
    setVisibleLimitPops(prevVisibleLimitPops => ({
      ...prevVisibleLimitPops,
      [key]: !prevVisibleLimitPops[key]
    }));
  };

  const handleCopyMneName = (mne: string) => {
    copy(mne);
  };

  return (
    <div>

      <Handle type="target" position={Position.Top} id="target-top" style={{opacity:0}}/>
      <Handle type="target" position={Position.Top} id="target-top-1" style={{opacity:0,left:150}}/>
      <Handle type="target" position={Position.Left} id="target-left" style={{opacity:0}}/>
      <Handle type="target" position={Position.Right} id="target-right" style={{opacity:0}}/>
      <Handle type="target" position={Position.Bottom} id="target-bottom" style={{opacity:0}}/>
      <Handle type="target" position={Position.Bottom} id="target-bottom-1" style={{opacity:0, left:150}}/>
      <Handle type="source" position={Position.Top} id="source-top" style={{opacity:0}}/>
      <Handle type="source" position={Position.Left} id="source-left" style={{opacity:0}}/>
      <Handle type="source" position={Position.Right} id="source-right" style={{opacity:0}}/>
      <Handle type="source" position={Position.Bottom} id="source-bottom" style={{opacity:0}}/>
      
      <div>

        <img src={logo} alt="Logo1" height={300}/>

      </div>

      <div className={scheme.titleContainer}>
        <p>Camera</p>
      </div>

      <div className={scheme.svgValueColCon}>
      {data.value && Object.entries(data.value).map(([key, innerData]) => (
            <div className={scheme.svgValueSpanContainer} key={key}>
            <span className={cx(scheme.svgKey, scheme.FnameKeyContainer)}onClick={() => handleMneNameClick(key)}>
                {key}
                {visibleTooltips[key] && <span className={scheme.mnameTooltip} onClick={() => handleCopyMneName(innerData.dbData.mne)}>{innerData.dbData.mne}</span>}
                <span data-comp="fname" className={scheme.svgFnameTooltip}>{innerData.fname}</span>
              </span>
              <span 
                onClick={() => handleValueChartClick(key)} 
                className={cx(
                  scheme.svgValue, 
                  css`color: ${innerData.dbData && innerData.dbData.live ? innerData.dbData.live : ""}`,
                  scheme.classMap[determineColorNew(innerData.dbData?.limit)] 
                )}
              >
                {innerData.dbData?.telemetry}
              </span>
              {innerData.dbData && innerData.dbData.unit && <span className={scheme.svgUnit}>{innerData.dbData.unit}</span>}
              {visibleLimitPops[key] && 
                  <div className={scheme.chartContainer}>
                    <PlotlyChart data={{ mne: innerData.dbData.mne, sc: innerData.dbData.spacecraft }}></PlotlyChart> 
                    <button className={scheme.plotButton} onClick={() => handleValueChartClick(key)}>
                        Close Plot
                    </button>
                  </div>}
            </div>
        ))}
        
        </div>
    </div>
  );
};

export default Cam;
