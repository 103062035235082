import * as scheme from './Scheme'; // Import your sty
import { css, cx } from '@emotion/css';

import React, {useState} from 'react'
import { Handle, Position } from 'reactflow';
import {InnerData, TLM, determineColorNew} from './utils';
import PlotlyChart from './PlotlyChart';
import copy from 'copy-to-clipboard';

type VisibleTooltips = { [key: string]: boolean };

interface PlimProp {
  data: {
    name: string;
    value: InnerData;
    power: TLM;
    priEn: TLM;
    priOn: TLM;
    secEn: TLM;
    secOn: TLM;
  };
}


const PlimBox: React.FC<PlimProp> = ({data}) => {

  const handleCopyMneName = (mne: string) => {
    copy(mne);
  };

  const [visibleTooltips, setVisibleTooltips] = useState<VisibleTooltips>({});

  const handleMneNameClick = (key: string) => {
    setVisibleTooltips(prevVisibleTooltips => ({
      ...prevVisibleTooltips,
      [key]: true
    }));
  
    setTimeout(() => {
      setVisibleTooltips(prevVisibleTooltips => ({
        ...prevVisibleTooltips,
        [key]: false
      }));
    }, 5000);
  };

  const [visibleLimitPops, setVisibleLimitPops] = useState<VisibleTooltips>({});

  const handleValueChartClick = (key: string) => {
    setVisibleLimitPops(prevVisibleLimitPops => ({
      ...prevVisibleLimitPops,
      [key]: !prevVisibleLimitPops[key]
    }));
  };

  const powerClass = (data.power && (data.power.telemetry === '1' || data.power.telemetry === 'On')) ? scheme.powerClass : '';

  const boomGrid = css`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 10px;
  width: 180px;
  height: 90px;
  justify-items: center;
  align-items: center;
`;

const labelStyle = css`
  grid-column: span 1;
  text-align: center;
`;

const getColor = (value: TLM) => {
  if (value && value.telemetry === "High") {
    return "green";
  } else {
    return "rgb(100,100,100)";
  }
};

const InnerDiv = css`
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
  `

  return (
    <div>
      <Handle type="target" position={Position.Top} id="target-top" style={{opacity:0}}/>
      <Handle type="target" position={Position.Left} id="target-left" style={{opacity:0}}/>
      <Handle type="target" position={Position.Right} id="target-right" style={{opacity:0}}/>
      <Handle type="target" position={Position.Left} id="target-left-4" style={{top:80, opacity:0}}/>
      <Handle type="target" position={Position.Bottom} id="target-bottom" style={{opacity:0}}/>
      <Handle type="source" position={Position.Bottom} id="source-bottom" style={{left:150, opacity:0}}/>
      
      <Handle type="source" position={Position.Top} id="source-top" style={{opacity:0}}/>
      <Handle type="source" position={Position.Left} id="source-left" style={{opacity:0}}/>
      <Handle type="source" position={Position.Right} id="source-right" style={{opacity:0}}/>
      <Handle type="source" position={Position.Right} id="source-right-1" style={{opacity:0, top:50}}/>
      <Handle type="source" position={Position.Bottom} id="source-bottom" style={{opacity:0}}/>

      <div className={cx(scheme.textboxContainer, powerClass, css`width:700px;`)}>

        <p>{data.name}</p>

        <div className={InnerDiv}>

        <div className={cx(scheme.textboxItems, css`width:400px`)}>
          {data.value && Object.entries(data.value).map(([key, innerData]) => (
            <div className={scheme.tbValueContainer} key={key}>
              <div className={scheme.FnameKeyContainer} onClick={() => handleMneNameClick(key)}>
                {key}:
                {visibleTooltips[key] && <span className={scheme.mnameTooltip} onClick={() => handleCopyMneName(innerData.dbData.mne)}>{innerData.dbData.mne}</span>}
                <span data-comp="fname" className={scheme.tbFnameTooltip}>{innerData.fname}</span>
              </div>
              <div className={scheme.tbValueSpanContainer} onClick={() => handleValueChartClick(key)}>
                <span 
                  className={cx(
                    scheme.value, 
                    css`color: ${innerData.dbData && innerData.dbData.live ? innerData.dbData.live : ""}`,
                    scheme.classMap[determineColorNew(innerData.dbData?.limit)], 
                  )}
                >
                  {innerData.dbData?.telemetry}
                </span>
                {innerData.dbData && innerData.dbData.unit && <span className={scheme.svgUnit}>{innerData.dbData.unit}</span>}
              </div>
              {visibleLimitPops[key] && 
                <div className={scheme.chartContainer}>
                  <PlotlyChart data={{ mne: innerData.dbData.mne, sc: innerData.dbData.spacecraft }}></PlotlyChart>
                  <button className={scheme.plotButton} onClick={() => handleValueChartClick(key)}>
                    Close Plot
                  </button>
                </div>}
            </div>
          ))}
        </div>

        <div className={boomGrid}>
          <div className={labelStyle}></div>
          <div className={labelStyle}>En</div>
          <div className={labelStyle}>On</div>

          <div className={labelStyle}>Pri</div>
          <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="-0.5 -0.5 30 30">
            <ellipse cx="15" cy="15" rx="13" ry="13" stroke="rgb(38,38,38)" fill={getColor(data.priEn)} strokeWidth="4"/>
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="-0.5 -0.5 30 30">
            <ellipse cx="15" cy="15" rx="13" ry="13" stroke="rgb(38,38,38)" fill={getColor(data.priOn)} strokeWidth="4"/>
          </svg>

          <div className={labelStyle}>Sec</div>
          <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="-0.5 -0.5 30 30">
            <ellipse cx="15" cy="15" rx="13" ry="13" stroke="rgb(38,38,38)" fill={getColor(data.secEn)} strokeWidth="4"/>
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="-0.5 -0.5 30 30">
            <ellipse cx="15" cy="15" rx="13" ry="13" stroke="rgb(38,38,38)" fill={getColor(data.secOn)} strokeWidth="4"/>
          </svg>
        </div>
        </div>
      </div>
    </div>
  )
}

export default PlimBox;
