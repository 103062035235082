import * as scheme from './Scheme'; // Import your sty
import * as htbScheme from './PayloadHtbScheme'; // Import your style
import { css, cx } from '@emotion/css';

import React, {useState} from 'react'
import { Handle, Position } from 'reactflow';
import {TbProps, determineColorNew} from './utils';
import PlotlyChart from './PlotlyChart';
import copy from 'copy-to-clipboard';

type VisibleTooltips = { [key: string]: boolean };

const PayloadHtb: React.FC<TbProps> = ({data}) => {

  const [visibleTooltips, setVisibleTooltips] = useState<VisibleTooltips>({});

  const handleMneNameClick = (key: string) => {
    setVisibleTooltips(prevVisibleTooltips => ({
      ...prevVisibleTooltips,
      [key]: true
    }));
  
    setTimeout(() => {
      setVisibleTooltips(prevVisibleTooltips => ({
        ...prevVisibleTooltips,
        [key]: false
      }));
    }, 5000);
  };

  const [visibleLimitPops, setVisibleLimitPops] = useState<VisibleTooltips>({});

  const handleValueChartClick = (key: string) => {
    setVisibleLimitPops(prevVisibleLimitPops => ({
      ...prevVisibleLimitPops,
      [key]: !prevVisibleLimitPops[key]
    }));
  };

  const powerClass = (data && data.power && (data.power.telemetry === '1' || data.power.telemetry === 'On')) ? scheme.powerClass : '';

  const getColor = (val: string) => {
    if (val === 'High') {
        return "rgb(72, 200, 44)";
    }
    else{
      return "rgb(100,100,100)";
    }
  }

  const [showPowerInfo, setShowPowerInfo] = useState(false);
  const [showPowerInfo1, setShowPowerInfo1] = useState(false);

  const handlePowerEllipseClick = () => {
    setShowPowerInfo(!showPowerInfo);

    setTimeout(() => {setShowPowerInfo(false)}, 2000);
  };

  const handlePowerEllipseClick1 = () => {
    setShowPowerInfo1(!showPowerInfo1);

    setTimeout(() => {setShowPowerInfo1(false)}, 2000);
  };
  
  const handleCopyMneName = (mne: string) => {
    copy(mne);
  };
  
  return (
    <div>
      <Handle type="target" position={Position.Top} id="target-top" style={{opacity:0}}/>
      <Handle type="target" position={Position.Left} id="target-left" style={{opacity:0}}/>
      <Handle type="target" position={Position.Right} id="target-right" style={{opacity:0}}/>
      <Handle type="target" position={Position.Left} id="target-left-4" style={{top:150, opacity:0}}/>
      <Handle type="target" position={Position.Bottom} id="target-bottom" style={{opacity:0}}/>
      <Handle type="source" position={Position.Bottom} id="source-bottom" style={{left:150, opacity:0}}/>
      
      <Handle type="source" position={Position.Top} id="source-top" style={{opacity:0}}/>
      <Handle type="source" position={Position.Left} id="source-left" style={{opacity:0}}/>
      <Handle type="source" position={Position.Right} id="source-right" style={{opacity:0}}/>
      <Handle type="source" position={Position.Right} id="source-right-1" style={{opacity:0, top:50}}/>
      <Handle type="source" position={Position.Bottom} id="source-bottom" style={{opacity:0}}/>

      <div className={cx(scheme.textboxContainer, htbScheme[data.textbox_container_class as htbScheme.ClassKey], powerClass)}>

      {data.pripwr && <div className={css`position: absolute;
      `}>
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width="60px" height="60px" viewBox="-0.5 -0.5 60 60" onClick={handlePowerEllipseClick}>
          <g>
            <g>
              <ellipse cx="25" cy="25" rx="15" ry="15" stroke="rgb(38,38,38)" fill={getColor(data.pripwr.telemetry)} pointerEvents="all" strokeWidth="6"/>
            </g>
          </g>
        </svg>
        {<span className={scheme.svgText}>Pri Power</span>}
            {showPowerInfo && <div className={scheme.svgPopValue}>
              <div className={scheme.svgValueSpanContainer}>
                <span className={scheme.svgKey} onClick={() => handleMneNameClick("PriPower")}>
                  Pri
                  <span className={scheme.svgFnameTooltip}>Pri Power</span>
                  {visibleTooltips['PriPower'] && <span className={scheme.mnameTooltip}>{data.pripwr.mne}</span>}
                </span>
                <span 
                className={cx(
                  scheme.svgValue,
                  css`color: ${data.pripwr.live}`,
                  scheme.classMap[determineColorNew(data.pripwr.limit)]
                )}
              >
                {data.pripwr.telemetry}
              </span>
              </div>
            </div>}
            </div>}

            {data.secpwr && <div className={css`position: absolute;
            right: 0;
      `}>
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width="60px" height="60px" viewBox="-0.5 -0.5 60 60" onClick={handlePowerEllipseClick1}>
          <g>
            <g>
              <ellipse cx="25" cy="25" rx="15" ry="15" stroke="rgb(38,38,38)" fill={getColor(data.secpwr.telemetry)} pointerEvents="all" strokeWidth="6"/>
            </g>
          </g>
        </svg>
        {<span className={scheme.svgText}>Sec Power</span>}
            {showPowerInfo1 && <div className={scheme.svgPopValue}>
              <div className={scheme.svgValueSpanContainer}>
                <span className={scheme.svgKey} onClick={() => handleMneNameClick("SecPower")}>
                  Sec
                  <span className={scheme.svgFnameTooltip}>Sec Power</span>
                  {visibleTooltips['SecPower'] && <span className={scheme.mnameTooltip}>{data.secpwr.mne}</span>}
                </span>
                <span 
                className={cx(
                  scheme.svgValue,
                  css`color: ${data.secpwr.live}`,
                  scheme.classMap[determineColorNew(data.secpwr.limit)]
                )}
              >
                {data.secpwr.telemetry}
              </span>
              </div>
            </div>}
            </div>}

        <p>{data.name}</p>
        <div className={scheme.tbHorContainer}>
          {data.value && Object.entries(data.value).map(([key, innerData]) => (
            <div className={scheme.tbValueContainer} key={key}>
              <div className={scheme.FnameKeyContainer} onClick={() => handleMneNameClick(key)}>
                {key}:
                {visibleTooltips[key] && <span className={scheme.mnameTooltip} onClick={() => handleCopyMneName(innerData.dbData.mne)}>{innerData.dbData.mne}</span>}
                <span data-comp="fname" className={scheme.tbFnameTooltip}>{innerData.fname}</span>
              </div>
              <div className={scheme.tbValueSpanContainer} onClick={() => handleValueChartClick(key)}>
                <span 
                  className={cx(
                    scheme.value, 
                    css`color: ${innerData.dbData && innerData.dbData.live ? innerData.dbData.live : ""}`,
                    scheme.classMap[determineColorNew(innerData.dbData?.limit)], 
                  )}
                >
                  {innerData.dbData?.telemetry}
                </span>
                {innerData.dbData && innerData.dbData.unit && <span className={scheme.svgUnit}>{innerData.dbData.unit}</span>}
              </div>
              {visibleLimitPops[key] && 
                <div className={scheme.chartContainer}>
                  <PlotlyChart data={{ mne: innerData.dbData.mne, sc: innerData.dbData.spacecraft }}></PlotlyChart>
                  <button className={scheme.plotButton} onClick={() => handleValueChartClick(key)}>
                    Close Plot
                  </button>
                </div>}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default PayloadHtb;
