import { css } from '@emotion/css';
import React from 'react'
import { Handle, Position } from 'reactflow';

interface EMAGProp {
  data: {
    power: {
      telemetry: string;
      unit: string;
      limit: string;
      live: string;
    };
  };
}

const EMAG: React.FC<EMAGProp> = ({data}) => {

  const emagContainer = css`
  width: 625px;
  height: 425px;
  border: 5px dashed rgb(33, 110, 224);
  position: relative;
`;


  return (
    <div className={emagContainer}>
      
      <Handle type="target" position={Position.Top} id="target-top" style={{opacity:0}}/>
      <Handle type="target" position={Position.Left} id="target-left" style={{opacity:0}}/>
      <Handle type="target" position={Position.Right} id="target-right" style={{opacity:0}}/>
      <Handle type="target" position={Position.Right} id="target-right-1" style={{top:230}}/>
      <Handle type="target" position={Position.Bottom} id="target-bottom" style={{opacity:0}}/>
      <Handle type="source" position={Position.Top} id="source-top" style={{opacity:0}}/>
      <Handle type="source" position={Position.Left} id="source-left" style={{opacity:0}}/>
      <Handle type="source" position={Position.Right} id="source-right" style={{opacity:0}}/>
      <Handle type="source" position={Position.Bottom} id="source-bottom" style={{opacity:0}}/>
    </div>
  )
}

export default EMAG;
