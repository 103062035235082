import * as scheme from './Scheme'; // Import your styles
import { css, cx } from '@emotion/css';
import React, { useEffect, useState } from "react";
import { InnerData, TLM, determineColorNew} from "./utils";
import { Handle, Position } from "reactflow";
import logo from '../img/EESA.png';
import PlotlyChart from "./PlotlyChart";
import copy from 'copy-to-clipboard';

interface EESAProp {
  data: {
    temp: InnerData;
    power: {
      telemetry: string;
      unit: string;
      limit: string;
      live: string;
      mne: string;
    };
    curr:TLM;
    valueI: InnerData;
    value: InnerData;
    valueE: InnerData;
    valueIAtt: InnerData;
    valueEAtt: InnerData;
  };
}

type VisibleTooltips = { [key: string]: boolean };

const EESA: React.FC<EESAProp> = ({ data }) => {

  const [visibleTooltips, setVisibleTooltips] = useState<VisibleTooltips>({});

  const handleMneNameClick = (key: string) => {
    setVisibleTooltips(prevVisibleTooltips => ({
      ...prevVisibleTooltips,
      [key]: true
    }));
  
    setTimeout(() => {
      setVisibleTooltips(prevVisibleTooltips => ({
        ...prevVisibleTooltips,
        [key]: false
      }));
    }, 5000);
  };

  const [visibleTooltips1, setVisibleTooltips1] = useState<VisibleTooltips>({});

  const handleMneNameClick1 = (key: string) => {
    setVisibleTooltips1(prevVisibleTooltips1 => ({
      ...prevVisibleTooltips1,
      [key]: true
    }));
  
    setTimeout(() => {
      setVisibleTooltips1(prevVisibleTooltips1 => ({
        ...prevVisibleTooltips1,
        [key]: false
      }));
    }, 5000);
  };

  const handleCopyMneName = (mne: string) => {
    copy(mne);
  };

  const [visibleLimitPops, setVisibleLimitPops] = useState<VisibleTooltips>({});

  const handleValueChartClick = (key: string) => {
    setVisibleLimitPops(prevVisibleLimitPops => ({
      ...prevVisibleLimitPops,
      [key]: !prevVisibleLimitPops[key]
    }));
  };

  const [visibleLimitPopsI, setVisibleLimitPopsI] = useState<VisibleTooltips>({});

  const handleValueChartClickI = (key: string) => {
    setVisibleLimitPopsI(prevVisibleLimitPops => ({
      ...prevVisibleLimitPops,
      [key]: !prevVisibleLimitPops[key]
    }));
  };

  const [visibleLimitPopsE, setVisibleLimitPopsE] = useState<VisibleTooltips>({});

  const handleValueChartClickE = (key: string) => {
    setVisibleLimitPopsE(prevVisibleLimitPops => ({
      ...prevVisibleLimitPops,
      [key]: !prevVisibleLimitPops[key]
    }));
  };
  
  const [isValueIAttVisible, setIsValueIAttVisible] = useState(false);

  const handleEESAIClick = () => {
    setIsValueIAttVisible(prev => !prev);
  };

  const [isValueEAttVisible, setIsValueEAttVisible] = useState(false);

  const handleEESAEClick = () => {
    setIsValueEAttVisible(prev => !prev);
  };

  const containerStyle = css`
    padding: 5px;
    width: 900px;
    height: 650px;
    
  `;

  const valueIAttStyle = css`
  margin: 10px 0;
  `;

  const valueEAttStyle = css`
  margin: 10px 0;
  `;

  const [pIstyle, setPIstyle] = useState('');
  const [pEstyle, setPEstyle] = useState('');

  const checkOutOfLimitsI = (data: InnerData) => {
    Object.entries(data).forEach(([key, innerData]) => {
      if (innerData && innerData.dbData && innerData.dbData.limit) {
        const limitClass = determineColorNew(innerData.dbData.limit);
        const schemeClass = scheme.classMap[limitClass];
        if (schemeClass) {
          setPIstyle(schemeClass);
        }
      }
    });
  };

  const checkOutOfLimitsE = (data: InnerData) => {
    Object.entries(data).forEach(([key, innerData]) => {
      if (innerData && innerData.dbData && innerData.dbData.limit) {
        const limitClass = determineColorNew(innerData.dbData.limit);
        const schemeClass = scheme.classMap[limitClass];
        if (schemeClass) {
          setPEstyle(schemeClass);
        }
      }
    });
  };

  useEffect(() => {
    checkOutOfLimitsI(data.valueIAtt);
    checkOutOfLimitsE(data.valueEAtt);
  }, [data]);

  
  return (
    <div className={containerStyle}>

      <p> Electrostatic Analyzer (EESA)</p>

      <Handle type="target" position={Position.Top} id="target-top" style={{opacity:0}}/>
      <Handle type="target" position={Position.Top} id="target-top-1" style={{opacity:0, left:550}}/>
      <Handle type="target" position={Position.Left} id="target-left" style={{opacity:0}}/>
      <Handle type="target" position={Position.Left} id="target-left-1" style={{opacity:0, top:530}}/>
      <Handle type="target" position={Position.Right} id="target-right" style={{opacity:0}}/>
      <Handle type="target" position={Position.Right} id="target-right-1" style={{opacity:0,top:600}}/>
      <Handle type="target" position={Position.Right} id="target-right-2" style={{opacity:0,top:550}}/>
      <Handle type="target" position={Position.Bottom} id="target-bottom" style={{opacity:0}}/>
      <Handle type="target" position={Position.Bottom} id="target-bottom-1" style={{opacity:0,left:850}}/>
      <Handle type="source" position={Position.Top} id="source-top" style={{opacity:0}}/>
      <Handle type="source" position={Position.Left} id="source-left" style={{opacity:0}}/>
      <Handle type="source" position={Position.Right} id="source-right" style={{opacity:0}}/>
      <Handle type="source" position={Position.Bottom} id="source-bottom" style={{opacity:0}}/>
      
      <div>

        <img src={logo} alt="Logo1" height={280}/>

      </div>

      <div className={css`
      display: flex;
      justify-content: space-between;
    `}>
        <div>
          <span onClick={handleEESAIClick} className={pIstyle}>EESA-i +</span>
          <div className={scheme.svgValueColCon}>
          {data.valueI && Object.entries(data.valueI).map(([key, innerData]) => (
            <div className={scheme.svgValueSpanContainer} key={key}>
            <span className={cx(scheme.svgKey, scheme.FnameKeyContainer)}onClick={() => handleMneNameClick1(key)}>
                {key}
                {visibleTooltips1[key] && <span className={scheme.mnameTooltip} onClick={() => handleCopyMneName(innerData.dbData.mne)}>{innerData.dbData.mne}</span>}
                <span data-comp="fname" className={scheme.svgFnameTooltip}>{innerData.fname}</span>
              </span>
              <span 
                onClick={() => handleValueChartClickI(key)} 
                className={cx(
                  scheme.svgValue, 
                  css`color: ${innerData.dbData && innerData.dbData.live ? innerData.dbData.live : ""}`,
                  scheme.classMap[determineColorNew(innerData.dbData?.limit)] 
                )}
              >
                {innerData.dbData?.telemetry}
              </span>
              {innerData.dbData && innerData.dbData.unit && <span className={scheme.svgUnit}>{innerData.dbData.unit}</span>}
              {visibleLimitPopsI[key] && 
                  <div className={scheme.chartContainer}>
                    <PlotlyChart data={{ mne: innerData.dbData.mne, sc: innerData.dbData.spacecraft }}></PlotlyChart> 
                    <button className={scheme.plotButton} onClick={() => handleValueChartClickI(key)}>
                        Close Plot
                    </button>
                  </div>}
            </div>
          ))}
          
          </div>
          {isValueIAttVisible && (
            <div className={cx(scheme.svgValueColCon, valueIAttStyle)}>
              {data.valueIAtt && Object.entries(data.valueIAtt).map(([key, innerData]) => (
                <div className={scheme.svgValueSpanContainer} key={key}>
                  <span className={cx(scheme.svgKey, scheme.FnameKeyContainer)} onClick={() => handleMneNameClick1(key)}>
                    {key}
                    {visibleTooltips1[key] && <span className={scheme.mnameTooltip} onClick={() => handleCopyMneName(innerData.dbData.mne)}>{innerData.dbData.mne}</span>}
                    <span data-comp="fname" className={scheme.svgFnameTooltip}>{innerData.fname}</span>
                  </span>
                  <span
                    onClick={() => handleValueChartClickI(key)}
                    className={cx(
                      scheme.svgValue, 
                      css`color: ${innerData.dbData && innerData.dbData.live ? innerData.dbData.live : ""}`,
                      scheme.classMap[determineColorNew(innerData.dbData.limit)] 
                    )}
                  >
                    {innerData.dbData?.telemetry}
                  </span>
                  {innerData.dbData && innerData.dbData.unit && <span className={scheme.svgUnit}>{innerData.dbData.unit}</span>}
                  {visibleLimitPopsI[key] &&
                    <div className={scheme.chartContainer}>
                      <PlotlyChart data={{ mne: innerData.dbData.mne, sc: innerData.dbData.spacecraft }}></PlotlyChart>
                      <button className={scheme.plotButton} onClick={() => handleValueChartClickI(key)}>
                        Close Plot
                      </button>
                    </div>}
                </div>
              ))}
            </div>
          )}
        </div>
        
        <div>
          <span>EESA-board</span>
          <div className={scheme.svgValueColCon}>
          {data.value && Object.entries(data.value).map(([key, innerData]) => (
            <div className={scheme.svgValueSpanContainer} key={key}>
            <span className={cx(scheme.svgKey, scheme.FnameKeyContainer)}onClick={() => handleMneNameClick(key)}>
                {key}
                {visibleTooltips[key] && <span className={scheme.mnameTooltip} onClick={() => handleCopyMneName(innerData.dbData.mne)}>{innerData.dbData.mne}</span>}
                <span data-comp="fname" className={scheme.svgFnameTooltip}>{innerData.fname}</span>
              </span>
              <span 
                onClick={() => handleValueChartClick(key)} 
                className={cx(
                  scheme.svgValue, 
                  css`color: ${innerData.dbData && innerData.dbData.live ? innerData.dbData.live : ""}`,
                  scheme.classMap[determineColorNew(innerData.dbData?.limit)] 
                )}
              >
                {innerData.dbData?.telemetry}
              </span>
              {innerData.dbData && innerData.dbData.unit && <span className={scheme.svgUnit}>{innerData.dbData.unit}</span>}
              {visibleLimitPops[key] && 
                  <div className={scheme.chartContainer}>
                    <PlotlyChart data={{ mne: innerData.dbData.mne, sc: innerData.dbData.spacecraft }}></PlotlyChart> 
                    <button className={scheme.plotButton} onClick={() => handleValueChartClick(key)}>
                        Close Plot
                    </button>
                  </div>}
            </div>
        ))}
          
          </div>
        </div>
        <div>
          <span onClick={handleEESAEClick} className={pEstyle}>EESA-e +</span>
          <div className={scheme.svgValueColCon}>
          {data.valueE && Object.entries(data.valueE).map(([key, innerData]) => (
            <div className={scheme.svgValueSpanContainer} key={key}>
            <span className={cx(scheme.svgKey, scheme.FnameKeyContainer)}onClick={() => handleMneNameClick(key)}>
                {key}
                {visibleTooltips[key] && <span className={scheme.mnameTooltip} onClick={() => handleCopyMneName(innerData.dbData.mne)}>{innerData.dbData.mne}</span>}
                <span data-comp="fname" className={scheme.svgFnameTooltip}>{innerData.fname}</span>
              </span>
              <span 
                onClick={() => handleValueChartClickE(key)} 
                className={cx(
                  scheme.svgValue, 
                  css`color: ${innerData.dbData && innerData.dbData.live ? innerData.dbData.live : ""}`,
                  scheme.classMap[determineColorNew(innerData.dbData?.limit)] 
                )}
              >
                {innerData.dbData?.telemetry}
              </span>
              {innerData.dbData && innerData.dbData.unit && <span className={scheme.svgUnit}>{innerData.dbData.unit}</span>}
              {visibleLimitPopsE[key] && 
                  <div className={scheme.chartContainer}>
                    <PlotlyChart data={{ mne: innerData.dbData.mne, sc: innerData.dbData.spacecraft }}></PlotlyChart> 
                    <button className={scheme.plotButton} onClick={() => handleValueChartClickE(key)}>
                        Close Plot
                    </button>
                  </div>}
            </div>
          ))}
          
          </div>
          {isValueEAttVisible && (
            <div className={cx(scheme.svgValueColCon, valueEAttStyle)}>
              {data.valueEAtt && Object.entries(data.valueEAtt).map(([key, innerData]) => (
                <div className={scheme.svgValueSpanContainer} key={key}>
                  <span className={cx(scheme.svgKey, scheme.FnameKeyContainer)} onClick={() => handleMneNameClick(key)}>
                    {key}
                    {visibleTooltips[key] && <span className={scheme.mnameTooltip} onClick={() => handleCopyMneName(innerData.dbData.mne)}>{innerData.dbData.mne}</span>}
                    <span data-comp="fname" className={scheme.svgFnameTooltip}>{innerData.fname}</span>
                  </span>
                  <span
                    onClick={() => handleValueChartClickE(key)}
                    className={cx(
                      scheme.svgValue, 
                      css`color: ${innerData.dbData && innerData.dbData.live ? innerData.dbData.live : ""}`,
                      scheme.classMap[determineColorNew(innerData.dbData?.limit)] 
                    )}
                  >
                    {innerData.dbData?.telemetry}
                  </span>
                  {innerData.dbData && innerData.dbData.unit && <span className={scheme.svgUnit}>{innerData.dbData.unit}</span>}
                  {visibleLimitPopsE[key] &&
                    <div className={scheme.chartContainer}>
                      <PlotlyChart data={{ mne: innerData.dbData.mne, sc: innerData.dbData.spacecraft }}></PlotlyChart>
                      <button className={scheme.plotButton} onClick={() => handleValueChartClickE(key)}>
                        Close Plot
                      </button>
                    </div>}
                </div>
              ))}
            </div>
          )}
          
        </div>
      </div>
        
    </div>
  );
};

export default EESA;
