import { css } from '@emotion/css';
import React from 'react'

interface PayloadPanelProp {
  data: {
    source: string;
  };
}

const PayloadPanel: React.FC<PayloadPanelProp> = ({data}) => {

  let color;

  if (data.source.includes('escg')) {
    color = 'gold';
  }else if (data.source.includes('escb')) {
    color = 'rgb(13, 57, 152)';
  }else{
    color = 'gray';
  }

  //border: 6px solid rgb(13, 57, 152)

  const panelStyle = css`
  width: 2600px;
  height: 1675px;
  border: 6px solid ${color};
  background-color: rgb(38, 38, 38);
`;

  return (
    <div className={panelStyle}></div>
  )
}

export default PayloadPanel;
